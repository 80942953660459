import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import _ from 'lodash';
import { _user } from 'std';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { messageBuilder } from 'utils/social-media/messageBuilder';
import { getClosestRegion } from 'utils/latlngFunctions';
import {
    formatAsCurrency,
    getCharityFinancialStats,
    getDonateBulksFromPickups,
    isAUSRegion,
    isEXPRegion,
    isMXDRegion,
    isCONRegion,
    getGPSLocation,
    getEnvName,
    isProductionEnv
} from 'utils/misc';

import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';

import printIcon from 'icons/print_100.png';
import mxdGiftIcon from 'icons/gift_mxd.png';
import conGiftIcon from 'icons/gift_con.png';
import ausGiftIcon from 'icons/gift_aus.png';
import stdGiftIcon from 'icons/gift_std.png';

import {
    MSG_SOCIAL_CUSTOMER_DEFAULT,
    MSG_SOCIAL_ORGANIZATION_DEFAULT,
    MSG_SOCIAL_CUSTOMER_RECYCLED,
    MSG_SOCIAL_CUSTOMER_DONATED,
    ROLES,
    ACCESS_DOOR,
    QR_SCAN,
    EXPRESS,
    DROPANDGO,
    INSTRUCTIONS
} from 'constants.js';

import {
    profileNotComplete,
    emailIsVerified,
    getCharityPreferred,
    getSubdivisionPreferred,
    getAppBarHeight,
    deviceHelper,
    getCustomerName
} from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';
import { Icon as MDIcon } from '@mdi/react';
import { mdiArrowDownDropCircle, mdiLockCheck } from '@mdi/js';

import DrawerContent from './DrawerContent.js';
import Dashboard from '../Customers/Dashboard/Dashboard';
import Profile from '../Customers/Profile/Profile';
import History from '../Customers/History/History';
import CharityDashboard from '../Customers/CharityDashboard/CharityDashboard';
import CharityHistory from '../Customers/CharityHistory/CharityHistory';
import CharityDonations from '../Customers/CharityDonations/CharityDonations';
import CharityTaxReceipts from '../Customers/CharityTaxReceipts/CharityTaxReceipts';
import CharityProfile from '../Customers/CharityProfile/CharityProfile';
import SubdivisionPermissions from 'components/CustomInputs/SubdivisionPermissions';

import Error from 'components/Error/Error';
import QrScanDialog from 'components/Dialogs/QR/QrScanDialog.js';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PickupDialog from 'components/Dialogs/PickupBooking';
import VerificationDialog from 'components/Dialogs/VerificationDialog/VerificationDialog';
import RedemptionDialog from 'components/RedemptionDialog';
import RecurringFeesDialog from 'components/RecurringFeesDialog';
import UserPreferencesDialog from 'components/UserPreferencesDialog';
import RefreshRequiredDialog from 'components/BlockingDialogs/RefreshRequiredDialog';
import PickupConfirmationDialog from 'components/PickupConfirmationDialog/PickupConfirmationDialog';
import CharitySelectedDialog from 'components/CharitySelectedDialog/CharitySelectedDialog';
import CharityInfoDialog from 'components/CharityInfoDialog/CharityInfoDialog';
import ShareAndFeedbackDialog from 'components/ShareAndFeedbackDialog/ShareAndFeedbackDialog';
import CombinedPickupsWidget from 'components/CustomerWidgets/CombinedPickupsWidget/CombinedPickupsWidget';
import CharityInfoWidget from 'components/CustomerWidgets/CharityInfoWidget';
import CharityStatsDialog from 'components/CharityStatsDialog/CharityStatsDialog';
import ReferralDialog from 'components/ReferralDialog/ReferralDialog';
import Announcement from 'components/Announcement/';
import ReactivateAccountDialog from 'components/Dialogs/Customer/ReactivateAccountDialog';
import SwitchAccountDialog from 'components/Dialogs/Charity/SwitchAccountDialog';

import LargeMap from 'components/LargeMap/LargeMap';

import ContactForm from 'components/ContactForm/ContactForm';
import HowItWorks from 'components/InfoDialogs/HowItWorks';
import FAQ from 'components/InfoDialogs/FAQ';

import CharityGrowthPlanDialog from 'components/CharityGrowthPlan/CharityGrowthPlanDialog';

import * as terms from 'localizations/terms';

import './CustomerSite.css';
// helpers
import pickupHelper from 'helpers/pickupHelper';
import bulkHelper from '../../helpers/bulkHelper.js';
import { getNextPlatformToRateOn } from '../../utils/social-media/rateFunctions';
import { getCustomerFinancialStats, getEnvironmentalStats } from '../../utils/misc';
import { Fab } from '@material-ui/core';
import AccountDeletedDialog from 'components/Dialogs/Customer/AccountDeletedDialog/index.js';
import VolumeReportDialog from 'components/VolumeReportDialog/index.js';

// import LocalizationContext from 'utils/contexts/LocalizationContext'; TODO
import { loc } from '../../localizations/localizationHandler';

import { AVAILABLE_LANGS } from '../../constants';
import LocalizationContext from 'utils/contexts/LocalizationContext.js';
import SystemHistoryDialog from 'components/Dialogs/Customer/SystemHistoryDialog/index.js';
import IPConflictDialog from 'components/Dialogs/Customer/IPConflictDialog/index.js';
import ChildAccountInfoDialog from 'components/ChildAccountInfoDialog/ChildAccountInfoDialog.js';
import CharityTypeDialog from 'components/Dialogs/Charity/CharityTypeDialog.js';
import VerifyEmailDialog from 'components/Dialogs/Customer/VerifyEmailDialog/index.js';
import DynamicGift from 'icons/DynamicGift.js';

const availableLangs = AVAILABLE_LANGS[process.env.REACT_APP_REGION_EXT];

const logo = require(process.env.REACT_APP_LOGO);

const DRAWER_WIDTH = 280; // in px

const REFERRAL_STEP = 'REFERRAL_STEP';
const SUMMARY_STEP = 'SUMMARY_STEP';
//const SHARE_AND_RATE_STEP = 'SHARE_AND_RATE_STEP';
const SHARE_STEP = 'SHARE_STEP';
const RATE_STEP = 'RATE_STEP';

// const Analytics = require('utils/analytics.js');
class CustomerSite extends Component {
    _id = this.props.match.params._id;
    homePath = '/customers/' + this._id;

    static contextType = LocalizationContext;

    etaTimer;

    state = {
        loading: true,
        reloadingCustomer: false,
        reloadingCharity: false,
        changingCharities: false,
        customer: undefined,
        pickups: undefined,
        invoices: undefined,
        pickupDialogPickups: [],
        redemptions: undefined,
        bulks: undefined,
        tips: undefined,
        taxReceipts: undefined,
        customerHistory: [],
        liveInfo: undefined,
        charity: undefined,
        charityBulks: [],
        charityBulksLoaded: false,
        charityRedemptions: undefined,
        charityPromo: undefined,
        charities: [],
        charitySelected: undefined, // displayed on the Map
        childAccountSelected: undefined,
        pastLocationSelected: undefined,
        openDropAndGo: false,
        dropAndGoCollector: undefined,
        dropAndGoTechnologies: [],
        dropLocations: [],
        accounts: [],
        accountSelectedIndex: undefined,
        accountRedemptionOptions: undefined,
        generalRedemptionOptions: undefined,
        minRedemptionCustomer: undefined,
        minRedemptionCharity: undefined,
        redemptionFee: undefined,
        disableInAppRedemptionCancel: false,
        drawerOpen: false,
        anchorEl: undefined,
        accountsAnchorEl: undefined,
        pickupDialogOpen: false,
        recurringFeesDialogOpen: false,
        rates: [],
        allRates: [],
        qrDialogOpen: false,
        redemptionDialogOpen: false,
        charityRedemptionDialogOpen: false,
        helpDialogEl: null,
        langDialogEl: null,
        contactDialogOpen: false,
        switchAccountDialogOpen: false,
        charityTypeDialogOpen: false,
        highlightAccountSwitch: false,
        howItWorksOpen: false,
        faqOpen: false,
        charityGrowthPlanOpen: false,
        pickupConfirmationDialogOpen: false,
        nextUnconfirmedPickup: undefined,
        charitySelectedDialogOpen: false,
        charityInfoDialogOpen: false,
        childAccountInfoDialogOpen: false,
        rateOurAppDialogOpen: false,
        askForFeedback: false,
        shareDialogStep: SHARE_STEP,
        shareDialogOpen: false,
        shareDialogObjs: [],
        shareDialogindex: 0,
        //shareDialogOnlyShowRateStep: false,
        charityStatsDialogOpen: false,
        referralDialogOpen: false,
        userPreferencesDialogOpen: false,
        volumeReportDialogOpen: false,
        charityShares: 0,
        isCordova: deviceHelper.isNativeApp(),
        enableReferrals: false,
        enableTipping: false,
        enableCustomerPickupImageUpload: true,
        defaultCoordinates: { latitude: 0, longitude: 0 },
        charityTaskI: 0,
        regions: [],
        collectorRatingPlatforms: [],
        nextPlatformToRateOn: null,
        growthPlanEnabled: true,
        minNumberTasksToBeFeatured: 5,
        totalCustomerCount: 0, //NOTE: rounded up to nearest multiple of 5000
        shareDialogindexOnNextClose: null,
        shareAndFeedbackDialogConfig: {},
        refundSummaryConfig: {},
        shareDialogShowSingleScreen: false,
        reactivateAccountDialogOpen: null,
        isSMSEnabled: true,
        accountDeletedDialogOpen: null,
        systemHistoryDialogOpen: false,
        IPConflictDialogOpen: false,
        charitiesRendered: [],
        mapCenter: null,
        commoditiesAvailable: [],
        commodityProjections: [],
        twoFactorAuthenticationWarning: '',
        services: [],
        customerCanChangeAccountType: false,
        // displayQRCode: false,
        // allowRequestLabels: false,
        multiBagDrops: false,
        rolePermissions: undefined,
        deactivateMenuEl: null,
        windowWidth: 0,
        customerPages: {
            [`/customers/${this._id}`]: loc('home', this.props.lang),
            [`/customers/${this._id}/history`]: loc('history', this.props.lang),
            [`/customers/${this._id}/profile`]: loc('account', this.props.lang)
        },

        charityPages: {
            [`/customers/${this._id}/charity`]: loc('dashboard', this.props.lang),
            [`/customers/${this._id}/charityHistory`]: loc('history', this.props.lang),
            [`/customers/${this._id}/charityProfile`]: terms.ORGANIZATION_NAME,
            [`/customers/${this._id}/charityDonations`]: loc('donations', this.props.lang),
            [`/customers/${this._id}/charityReceipts`]: loc('taxReceipts', this.props.lang),
            [`/customers/${this._id}/charitySubdivisions`]: loc('subdivisions', this.props.lang)
        },
        childAccountBulks: {},
        childAccountTips: {},
        childAccountRedemptions: {},
        dropLocationMarkerOverride: {},
        charityMarkerOverride: {},
        deletingAccount: false,
        deleteReason: '',
        disablingAccount: false,
        disableReason: { reason: '', comments: '' },
        taxGroups: [],
        currentLocation: {},
        emailVerificationRequired: false,
        emailVerificationRequiredForETransfer: false,
        adminPermissions: {},
        // customerPermissions: {},
        overrideTechnologyFilter: null,
        overrideTechnologyTypeForInstructions: null,
        fabOpen: false,
        collectionsDialogOpen: false
    };

    async componentDidMount() {
        const { auth, org } = this.props;
        // Apply admin theme if viewed by Operator:
        if (auth.accountType !== 'Customer') {
            this.props.onAdminTheme(true);
        }

        if (!_.isNil(org)) {
            this.setState({ charitySelectedDialogOpen: true });
        }

        this.appStateChangeListener = await App.addListener('appStateChange', async state => {
            // solves out of date issues when entering and leaving the application
            if (state.isActive) {
                await this.reloadCustomer();
            }
        });

        const customerData = await this.reloadCustomer();

        const charityData = await this.reloadCharity();

        this.handleReferralStatus(customerData);

        const coordinates = await getGPSLocation();
        if (!_.isNil(coordinates)) {
            this.setState({ currentLocation: { lat: coordinates.coords.latitude, lng: coordinates.coords.longitude } });
        }
        // Poll getDriverETA if a pending pickup is present on today's date
        const pickupsPending = _(customerData.pickups)
            .filter(p => _.isNil(p.completionDate))
            .sortBy(p => new Date(p.date))
            .value();

        const pickupsPendingToday = pickupsPending.filter(p =>
            moment(p.date)
                .tz(_.get(p, 'location.timezone', process.env.REACT_APP_REGION_TIMEZONE))
                .isSame(new Date(), 'day')
        );

        if (!_.isNil(pickupsPendingToday) && !_.isEmpty(pickupsPendingToday)) {
            await this.getDriverEta();
            this.etaTimer = setInterval(() => {
                if (!this.state.pickupDialogOpen) this.getDriverEta();
            }, 15 * 1000);
        }
        window.addEventListener('resize', this.updateSize);

        this.setState({ loading: false });

        this.loadDropLocations();
        this.loadTaxGroups();

        this.loadCharities();
        this.getCharityFeaturedInfoText();
    }

    loadCustomerSiteConfig = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/app/config/customersite', false, true);

        if (res.ok) {
            const commodityConfig = _.get(res, 'data.commodityConfig', false);
            this.handleCommodityConfig(commodityConfig);

            const smsConfig = _.get(res, 'data.smsConfig', {});
            this.handleSMSConfig(smsConfig);

            const markerConfig = _.get(res, 'data.markerConfig', {});
            this.handleMarkerConfig(markerConfig);

            const tippingConfig = _.get(res, 'data.tippingConfig', {});
            this.handleTippingConfig(tippingConfig);

            const defaultCoordinates = _.get(res, 'data.defaultCoordinates', { latitude: 0, longitude: 0 });
            this.handleDefaultCoordinates(defaultCoordinates);

            const pickupsConfig = _.get(res, 'data.pickupsConfig', {});
            this.handlePickupsConfig(pickupsConfig);

            const adminPermissions = _.get(res, 'data.adminPermissions', {});
            this.handleAdminPermissions(adminPermissions);

            const emailVerificationStatus = _.get(res, 'data.emailVerification', {});
            this.handleEmailVerificationStatus(emailVerificationStatus);

            const refundSummaryConfig = _.get(res, 'data.refundSummaryConfig', {});
            this.handleRefundSummaryConfig(refundSummaryConfig);

            const shareAndFeedbackDialogConfig = _.get(res, 'data.shareAndFeedbackDialogConfig', {});
            this.handleShareAndFeedbackDialogConfig(shareAndFeedbackDialogConfig);

            const allRates = _.get(res, 'data.allRates', {});
            this.handleRates(allRates);

            const redemptionConfig = _.get(res, 'data.redemptionConfig', {});
            this.handleRedemptionConfig(redemptionConfig);

            const twoFactorAuthenticationWarning = _.get(res, 'data.twoFactorAuthenticationWarning', '');
            this.handleTwoFactorAuthenticationWarning(twoFactorAuthenticationWarning);

            const multiBagDrops = _.get(res, 'data.multiBagDrops', false);
            this.handleMultiBagDrops(multiBagDrops);

            const customerCanChangeAccountType = _.get(res, 'data.customerCanChangeAccountType', false);
            this.handleCustomerCanChangeAccountType(customerCanChangeAccountType);

            const collectors = _.get(res, 'data.collectors', false);
            this.handleCollectors(collectors);
        }
    };

    handleCommodityConfig = commodityConfig => {
        const commoditiesAvailable = _.get(commodityConfig, 'commodities', []);
        const commodityProjections = _.get(commodityConfig, 'commodityProjections', []);

        this.setState({ commoditiesAvailable, commodityProjections });
    };

    loadRegions = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/regions', false, true);
        if (res.ok) {
            const regions = _.get(res, 'data.regions', []);
            this.setState({ regions });
        }
    };

    loadServices = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/services');
        if (res.ok) {
            const allServices = _.get(res, 'data.services', []);
            this.setState({
                services: allServices
            });
        }
    };

    loadCharities = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/charities/all', false, true);
        if (res.ok) {
            const charitiesFiltered = _.filter(res.data.charities, charity => charity.isFeatured && charity.approved);
            this.setState({ charities: res.data.charities, charitiesRendered: charitiesFiltered }); // the content will be shown at this step
        }
    };

    loadShareDialogObjs = async () => {
        let obj = await messageBuilder(
            this.props.http,
            MSG_SOCIAL_CUSTOMER_DEFAULT,
            {},
            null,
            undefined,
            this.props.lang
        );
        this.setState({ shareDialogObjs: [obj, _.cloneDeep(obj), _.cloneDeep(obj)] });
    };

    loadDropLocations = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/dropLocations', false, true);
        if (res.ok) {
            this.setState({ dropLocations: _.get(res, 'data.dropLocations', []) });
        }
    };

    loadTaxGroups = async () => {
        const { http } = this.props;

        const res = await http.getJSON('/taxGroups/getAllTaxGroups', false, true);
        if (res.ok) {
            this.setState({ taxGroups: _.get(res, 'data.collectionData', []) });
        }
    };

    handleSMSConfig = smsConfig => {
        this.setState({ isSMSEnabled: _.get(smsConfig, 'isEnabled', true) });
    };

    handleMarkerConfig = markerConfig => {
        this.setState({ dropLocationMarkerOverride: _.get(markerConfig, 'dropLocationMarker', {}) });
        this.setState({ charityMarkerOverride: _.get(markerConfig, 'charityMarker', {}) });
    };

    handleTippingConfig = tippingConfig => {
        this.setState({ enableTipping: _.get(tippingConfig, 'isEnabled', false) });
    };

    handleDefaultCoordinates = defaultCoordinates => {
        this.setState({ defaultCoordinates });
    };

    handlePickupsConfig = pickupsConfig => {
        this.setState({ enableCustomerPickupImageUpload: _.get(pickupsConfig, 'customerImageUploadEnabled', true) });
    };

    handleAdminPermissions = adminPermissions => {
        this.setState({ adminPermissions });
    };

    handleEmailVerificationStatus = emailVerification => {
        this.setState({
            emailVerificationRequired: _.get(emailVerification, 'emailVerificationRequired', false),
            emailVerificationRequiredForETransfer: _.get(
                emailVerification,
                'emailVerificationRequiredForETransfer',
                false
            )
        });
    };

    handleRefundSummaryConfig = refundSummaryConfig => {
        this.setState({ refundSummaryConfig });
    };

    handleShareAndFeedbackDialogConfig = shareAndFeedbackDialogConfig => {
        this.setState({ shareAndFeedbackDialogConfig });
    };

    handleRates = allRates => {
        this.setState({
            allRates: allRates,
            rates: _.get(allRates, '0.rates', [])
        });
    };

    handleRedemptionConfig = redemptionConfig => {
        this.setState({
            accountRedemptionOptions: {
                ...this.state.accountRedemptionOptions,
                customer: _.get(redemptionConfig, 'redemptionOptions', {})
            },
            generalRedemptionOptions: _.get(redemptionConfig, 'generalRedemptionOptions'),
            minRedemptionCustomer: _.get(redemptionConfig, 'minRedemptionCustomer'),
            redemptionFee: _.get(redemptionConfig, 'redemptionFee'),
            disableInAppRedemptionCancel: _.get(redemptionConfig, 'disableInAppRedemptionCancel')
        });
    };

    handleTwoFactorAuthenticationWarning = twoFactorAuthenticationWarning => {
        this.setState({ twoFactorAuthenticationWarning });
    };

    handleMultiBagDrops = multiBagDrops => {
        this.setState({ multiBagDrops });
    };

    handleCustomerCanChangeAccountType = customerCanChangeAccountType => {
        this.setState({ customerCanChangeAccountType });
    };

    handleCollectors = collectors => {
        this.setState({ collectors });
    };

    handleReferralStatus = customerData => {
        const { shareAndFeedbackDialogConfig } = this.state;

        const completedBulks = _.filter(
            customerData.bulks,
            bulk => !_.isNil(bulk.pickup) && bulk.pickup.complete && !bulk.pickup.aborted
        );

        this.setState({
            enableReferrals:
                _.get(shareAndFeedbackDialogConfig, 'referralScreen.enabled', false) && !_.isEmpty(completedBulks)
        });
    };

    loadRolePermissions = async () => {
        const { http, history } = this.props;

        const res = await http.getJSON('/system/rolePermissions');

        if (res.ok) {
            this.setState({ rolePermissions: _.get(res, 'data.rolePermissions') });
            if (
                res.data.rolePermissions &&
                !_.isNil(this.state.customerPages[this.props.location.pathname]) &&
                !_.get(res, 'data.rolePermissions.accounts.customer')
            ) {
                this.props.onSnackbar(loc('customerSnackBar1', this.props.lang), 'error');
                history.push('/');
            }
            if (
                res.data.rolePermissions &&
                !_.isNil(this.state.charityPages[this.props.location.pathname]) &&
                !_.get(res, 'data.rolePermissions.accounts.charity')
            ) {
                this.props.onSnackbar(
                    loc('customerSnackBar2', this.props.lang, { organization: terms.ORGANIZATION_NAME }),
                    'error'
                );
                history.push('/');
            }
        }
    };

    // loadCustomerPermissions = async () => {
    //     const { http } = this.props;

    //     const [resImpactReport, resTaxReceiptOptOut] = await Promise.all([
    //         http.getJSON('/system/configuration/showImpactReportInSideMenu'),
    //         http.getJSON('/system/configuration/allowTaxReceiptOptOut')
    //     ]);

    //     const customerPermissions = {};

    //     if (resImpactReport.ok) {
    //         customerPermissions.showImpactReportInSideMenu = resImpactReport.data.showImpactReportInSideMenu;
    //     }

    //     if (resTaxReceiptOptOut.ok) {
    //         customerPermissions.allowTaxReceiptOptOut = resTaxReceiptOptOut.data.allowTaxReceiptOptOut;
    //     }

    //     if (!_.isEmpty(customerPermissions)) {
    //         this.setState({
    //             customerPermissions: customerPermissions
    //         });
    //     }
    // };

    loadShareDialogObjs = async () => {
        let obj = await messageBuilder(
            this.props.http,
            MSG_SOCIAL_CUSTOMER_DEFAULT,
            {},
            null,
            undefined,
            this.props.lang
        );
        this.setState({ shareDialogObjs: [obj, _.cloneDeep(obj), _.cloneDeep(obj)] });
    };

    getCharityFeaturedInfoText = async () => {
        const { http } = this.props;

        const [resCustomerCount, resConfigCharityTasks] = await Promise.all([
            http.getJSON('/totalCustomerCount'),
            http.getJSON('/config/charityTaskConfiguration')
        ]);

        if (resConfigCharityTasks.ok && resCustomerCount.ok) {
            const totalCustomerCount = resCustomerCount.data.total;
            const customerCountRounded = (Math.floor(totalCustomerCount / 5000) * 5000).toLocaleString(); //round down to nearest multiple of 5000
            const minNumberTasksToBeFeatured = resConfigCharityTasks.data.config.minimumVerifiedTasksForFeatured;
            const growthPlanEnabled = resConfigCharityTasks.data.config.growthPlanEnabled;

            this.setState({
                growthPlanEnabled,
                minNumberTasksToBeFeatured,
                totalCustomerCount: customerCountRounded
            });
        }
    };

    componentWillUnmount() {
        if (!_.isNil(this.appStateChangeListener)) {
            this.appStateChangeListener.remove();
        }
        clearInterval(this.etaTimer);
        window.removeEventListener('resize', this.updateSize);
    }

    getDriverEta = async () => {
        const { http } = this.props;
        const { liveInfo } = this.state;

        let resDriverEta = await http.getJSON(`/users/${this._id}/getDriverETA`, true, true);
        if (resDriverEta.ok) {
            if (!_.isEqual(liveInfo, resDriverEta.data)) {
                this.setState({ liveInfo: !_.isEmpty(resDriverEta.data) ? resDriverEta.data : undefined });
            }
            if (_.isEmpty(resDriverEta.data)) {
                await this.reloadCustomer();
                clearInterval(this.etaTimer);
            }
        } else {
            this.setState({ liveInfo: { isError: true } });
        }
    };

    reloadCustomer = async () => {
        const { http, auth } = this.props;
        const { reloadingCustomer } = this.state;

        if (reloadingCustomer) {
            return new Promise(resolve => setTimeout(resolve, 1000)); // Limit customer refreshes
        }
        this.setState({ reloadingCustomer: true });

        // Load configuration
        await Promise.all([
            this.loadCustomerSiteConfig(),
            this.loadRolePermissions(),
            this.loadShareDialogObjs(),
            this.loadRegions(),
            this.loadServices()
        ]);

        return await new Promise(async (resolve, reject) => {
            const {
                accounts,
                accountSelectedIndex,
                rolePermissions,
                history,
                customerPages,
                charityPages,
                regions
            } = this.state;

            let res = await http.getJSON('/users/customers/' + this._id);
            if (res.ok) {
                // Generate the accounts list and select the default one the first time this kicks in:
                let accountsUpdated = accounts;
                let accountSelectedIndexUpdated = accountSelectedIndex;
                let feedbackDialogindex = 0;

                if (_.isEmpty(accounts)) {
                    accountsUpdated.push('Customer');
                    accountSelectedIndexUpdated = 0;
                    if (!_.isEmpty(res.data.customer.charities)) {
                        res.data.customer.charities.forEach((charity, idx) => {
                            accountsUpdated.push(`Administrator-${charity._id}`);
                            if (
                                this.props.location.pathname.includes(charity._id) &&
                                !profileNotComplete(res.data.customer) &&
                                emailIsVerified(res.data.customer)
                            ) {
                                accountSelectedIndexUpdated = idx + 1;
                                feedbackDialogindex = 2;
                            }
                        });
                    }
                    if (!_.isNil(res.data.customer.multipleAccountAccessList)) {
                        res.data.customer.multipleAccountAccessList.forEach(account => {
                            accountsUpdated.push(account._id.toString());
                        });
                    }
                }
                if (!_.isNil(customerPages[this.props.location.pathname])) {
                    accountSelectedIndexUpdated = 0;
                    // } else if (
                    //     !_.isNil(charityPages[this.props.location.pathname]) &&
                    //     !profileNotComplete(res.data.customer) &&
                    //     emailIsVerified(res.data.customer)
                    // ) {
                    //     accountSelectedIndexUpdated = 1;
                    //     feedbackDialogindex = 2;
                }
                if (auth.accountType !== 'Customer' && rolePermissions) {
                    if (!_.get(rolePermissions, 'accounts.customer') && !_.get(rolePermissions, 'accounts.charity')) {
                        history.push(`/`);
                    } else if (!_.get(rolePermissions, 'accounts.charity')) {
                        accountSelectedIndexUpdated = 0;
                    } else if (!_.get(rolePermissions, 'accounts.charity') && !_.isEmpty(res.data.customer.charities)) {
                        accountSelectedIndexUpdated = 1;
                    }
                }

                // First login:
                if (
                    !res.data.customer.adminView &&
                    res.data.customer.firstLogin &&
                    !profileNotComplete(res.data.customer) &&
                    emailIsVerified(res.data.customer) &&
                    _.isEmpty(res.data.customer.charities)
                ) {
                    // this.setState({ pickupDialogOpen: true });
                    this.setState({ howItWorksOpen: true });
                } else if (
                    !res.data.customer.adminView &&
                    res.data.customer.firstLogin &&
                    !profileNotComplete(res.data.customer)
                ) {
                    this.setState({ howItWorksOpen: true });
                    // this.setState({ switchAccountDialogOpen: true });
                    this.setState({ highlightAccountSwitch: true });
                } else if (
                    !res.data.customer.adminView &&
                    res.data.customer.firstLoginAfterCharityApproved &&
                    !profileNotComplete(res.data.customer)
                ) {
                    this.setState({ charityGrowthPlanOpen: true });
                }

                if (
                    _user.isCollectorAdmin(this.props.auth) &&
                    res.data.customer.firstLogin &&
                    !profileNotComplete(res.data.customer) &&
                    emailIsVerified(res.data.customer) &&
                    _.isEmpty(res.data.customer.charities)
                ) {
                    this.setState({ pickupDialogOpen: true });
                }

                // Pickup confirmation:
                //let pickupConfirmationDialogOpen = false;

                let unconfirmedPickup = _.find(
                    res.data.pickups,
                    pickup => !pickup.confirmed && _.isNil(pickup.completionDate) && !pickup.unserviced
                );
                let nextUnconfirmedPickup = unconfirmedPickup;
                /*if (!_.isNil(unconfirmedPickup) && moment(unconfirmedPickup.date).diff(moment(), 'hours') <= 48) {
                    pickupConfirmationDialogOpen = true;
                    //nextUnconfirmedPickup = unconfirmedPickup;
                }*/

                let unsummarizedCompletedBulks = _.filter(res.data.bulks, function(bulk) {
                    return bulkHelper.isCompleted(bulk) && !bulkHelper.isSummarized(bulk);
                });

                // Grab all the non-beverage bulks that are not donated from the unsummarized completed bulks
                let undonatedNonBevs = _.filter(unsummarizedCompletedBulks, function(bulk) {
                    return (
                        !bulkHelper.isBeverage(bulk) &&
                        !bulkHelper.isCollectionBins(bulk) &&
                        !bulkHelper.isDonating(bulk)
                    );
                });

                // If all the unsummarized completed bulks are undonated non-beverage bulks then flip their "summarized" flags to true
                // as the ShareAndFeedbackDialog will not be appearing
                let allUndonatedNonBevs = _.isEqual(unsummarizedCompletedBulks, undonatedNonBevs);
                if (allUndonatedNonBevs && this.props.auth.accountType === 'Customer') {
                    http.post(`/bulks/setSummarized`, {
                        bulk_ids: undonatedNonBevs.map(bulk => bulk._id),
                        summarizedVal: true
                    });
                }

                // ShareAndFeedbackDialog will only appear if there exists unsummarized completed bulks that are not all undonated non-beverage bulks
                let askForFeedback =
                    !_.isEmpty(unsummarizedCompletedBulks) &&
                    !allUndonatedNonBevs &&
                    res.data.customer.settings.surveyFeedbackEnabled;

                let feedbackDialog = this.state.shareDialogObjs;

                let lastCompletedBulk = _.last(unsummarizedCompletedBulks);

                const donatedBulksPassToSummary = getDonateBulksFromPickups(res.data.pickups, res.data.bulks);

                let collectorRatingPlatforms = [];
                let nextPlatformToRateOn = null;

                if (!_.isNil(lastCompletedBulk)) {
                    const collectorId = _.get(lastCompletedBulk, 'collector._id');

                    if (!_.isNil(collectorId)) {
                        //get rating platform data and rating url for collector of last bulk
                        let collectorRatingPlatformsRes = await http.getJSON(
                            `/collectors/${collectorId}/getRatingPlatforms`,
                            true,
                            false
                        );

                        if (collectorRatingPlatformsRes.ok) {
                            collectorRatingPlatforms = collectorRatingPlatformsRes.data.ratingPlatforms;
                            nextPlatformToRateOn = getNextPlatformToRateOn(collectorRatingPlatforms, res.data.customer);
                        }
                    }
                    // STAGING ONLY
                } else if (process.env.REACT_APP_ENV === 'TEST') {
                    let lastCompletedSummarizedBulk = _.last(res.data.bulks);
                    const collectorId = _.get(lastCompletedSummarizedBulk, 'collector._id');

                    if (!_.isNil(collectorId)) {
                        //get rating platform data and rating url for collector of last bulk
                        let collectorRatingPlatformsRes = await http.getJSON(
                            `/collectors/${collectorId}/getRatingPlatforms`,
                            true,
                            false
                        );

                        if (collectorRatingPlatformsRes.ok) {
                            collectorRatingPlatforms = collectorRatingPlatformsRes.data.ratingPlatforms;
                            nextPlatformToRateOn = getNextPlatformToRateOn(collectorRatingPlatforms, res.data.customer);
                        }

                        //if no platforms remaining, just show first one
                        if (_.isNil(nextPlatformToRateOn)) {
                            nextPlatformToRateOn = _.first(collectorRatingPlatforms);
                        }
                    }
                }

                // city locations used to find closest cities to filter featured charities
                //set customer location from set to lat/lng of ip address if not currently set

                if (
                    !_.isEmpty(regions) &&
                    (_.isNil(res.data.customer.location) ||
                        _.isNil(res.data.customer.location.lat) ||
                        _.isNil(res.data.customer.location.lng))
                ) {
                    _.set(res.data.customer, 'location.lat', regions.lat);
                    _.set(res.data.customer, 'location.lng', regions.lng);
                }

                if (!_.isNil(lastCompletedBulk) && askForFeedback) {
                    // determine if they kept or donated the money
                    let amount = bulkHelper.getCustomerAmount(lastCompletedBulk);
                    feedbackDialogindex = 1;
                    if (_.isNil(lastCompletedBulk.charity)) {
                        feedbackDialog[feedbackDialogindex] = await messageBuilder(
                            this.props.http,
                            MSG_SOCIAL_CUSTOMER_RECYCLED,
                            {
                                amount: formatAsCurrency(amount, this.props.lang),
                                url: process.env.REACT_APP_ORIGIN_URL
                            },
                            getClosestRegion(res.data.customer.location, regions),
                            undefined,
                            this.props.lang
                        );
                    } else {
                        // donated pickup will add amount from commodities
                        amount = donatedBulksPassToSummary.reduce(
                            (sum, bulk) => sum + bulkHelper.getCustomerAmount(bulk),
                            0
                        );
                        feedbackDialog[feedbackDialogindex] = await messageBuilder(
                            this.props.http,
                            MSG_SOCIAL_CUSTOMER_DONATED,
                            {
                                amount: formatAsCurrency(amount, this.props.lang),
                                url: `${process.env.REACT_APP_ORIGIN_URL}/${_.get(
                                    lastCompletedBulk,
                                    'charity.url',
                                    ''
                                )}`,
                                organization: _.get(lastCompletedBulk, 'charity.name', 'N/A'),
                                taxReceiptsIssued: lastCompletedBulk.charity.taxReceiptsIssued
                            },
                            getClosestRegion(res.data.customer.location, regions),
                            undefined,
                            this.props.lang
                        );
                    }
                } else {
                    askForFeedback = false;
                }

                feedbackDialog[0] = await messageBuilder(
                    this.props.http,
                    MSG_SOCIAL_CUSTOMER_DEFAULT,
                    {},
                    getClosestRegion(res.data.customer.location, regions),
                    undefined,
                    this.props.lang
                );

                // data rollups
                const pickups = pickupHelper.rollupPickupsWithAssociatedBulks(
                    res.data.pickups,
                    res.data.bulks,
                    res.data.tips
                );
                // const pickups = pickupHelper.rollupPickupsWithAssociatedBulks(res.data.pickups, res.data.bulks);

                const accountDeletedDialogOpen =
                    this.state.accountDeletedDialogOpen === false
                        ? false
                        : _user.isDeleted(_.get(res, 'data.customer')); //Don't reopen dialog once dismissed

                const reactivateAccountDialogOpen =
                    this.state.reactivateAccountDialogOpen === false
                        ? false
                        : _user.isDisabled(_.get(res, 'data.customer')) &&
                          !_user.isBanned(_.get(res, 'data.customer')) &&
                          !_user.isDeleted(_.get(res, 'data.customer')); //Don't reopen dialog once dismissed

                // let twoFactorAuthenticationWarning = _.get(res, 'data.twoFactorAuthenticationWarning', '');
                this.setState(
                    {
                        customer: res.data.customer,
                        reloadingCustomer: false,
                        pickups,
                        pickupDialogPickups: pickups,
                        redemptions: res.data.redemptions,
                        // accountRedemptionOptions: {
                        //     ...this.state.accountRedemptionOptions,
                        //     customer: res.data.redemptionOptions
                        // },
                        // generalRedemptionOptions: res.data.generalRedemptionOptions,
                        // collectors: _.get(res, 'data.collectors', []),
                        bulks: res.data.bulks,
                        tips: res.data.tips,
                        invoices: res.data.invoices,
                        customerHistory: res.data.customerHistory,
                        taxReceipts: res.data.taxReceipts,
                        // minRedemptionCustomer: res.data.minRedemptionCustomer,
                        // redemptionFee: res.data.redemptionFee,
                        // disableInAppRedemptionCancel: res.data.disableInAppRedemptionCancel,
                        // rates: res.data.rates,
                        // allRates: res.data.allRates,
                        accounts: accountsUpdated,
                        accountSelectedIndex: accountSelectedIndexUpdated,
                        nextUnconfirmedPickup,
                        shareDialogStep:
                            askForFeedback && !this.state.shareDialogOpen ? SUMMARY_STEP : this.state.shareDialogStep,
                        shareDialogObjs: feedbackDialog,
                        shareDialogOpen:
                            this.props.auth.accountType !== 'Customer'
                                ? false
                                : askForFeedback
                                ? true
                                : this.state.shareDialogOpen,
                        shareDialogindex: feedbackDialogindex,
                        shareDialogShowSingleScreen: askForFeedback ? false : this.state.shareDialogShowSingleScreen,
                        qrDialogOpen: askForFeedback ? false : this.state.qrDialogOpen,
                        collectorRatingPlatforms,
                        nextPlatformToRateOn,
                        // regions,
                        reactivateAccountDialogOpen,
                        accountDeletedDialogOpen,
                        mapCenter: {
                            lat: parseFloat(res.data.customer.location.lat),
                            lng: parseFloat(res.data.customer.location.lng)
                        },
                        // commoditiesAvailable,
                        // commodityProjections,
                        // twoFactorAuthenticationWarning,
                        // qrServiceEnabled,
                        // accessDoorServiceEnabled,
                        // displayQRCode: _.get(res, 'data.displayQRCode', false),
                        // allowRequestLabels: _.get(res, 'data.allowRequestLabels', false),
                        // multiBagDrops: _.get(res, 'data.multiBagDrops', false),
                        isLastInteraction: false,
                        childAccountBulks: res.data.childAccountBulks,
                        childAccountRedemptions: res.data.childAccountRedemptions,
                        childAccountTips: res.data.childAccountTips
                        // customerCanChangeAccountType: _.get(res, 'data.customerCanChangeAccountType', false)
                    },
                    () => resolve(res.data)
                );
            }
        });
    };

    reloadCharity = async (_id = null, changingCharities = false) => {
        const {
            customer,
            shareDialogStep,
            accountSelectedIndex,
            accounts,
            shareDialogindex,
            regions,
            rolePermissions,
            reloadingCharity
        } = this.state;

        if (!_.isEmpty(customer.charities) && (!rolePermissions || _.get(rolePermissions, 'accounts.charity'))) {
            if (reloadingCharity) {
                return new Promise(resolve => setTimeout(resolve, 200));
            }

            this.setState({ reloadingCharity: true, changingCharities });
            const charity_id = !_.isNil(_id)
                ? _id
                : accounts[accountSelectedIndex].includes('Administrator')
                ? accounts[accountSelectedIndex].split('-')[1]
                : customer.charities[0]._id;

            let res = await this.props.http.getJSON('/charities/' + charity_id);
            if (res.ok) {
                this.setState(res.data, () => console.log(this.state));

                let updatedShareDialogIndex = shareDialogindex;

                if (shareDialogStep === SUMMARY_STEP) {
                    updatedShareDialogIndex = 1;
                } else if (accountSelectedIndex === 1) {
                    updatedShareDialogIndex = 2;
                } else {
                    updatedShareDialogIndex = 0;
                }

                let updatedShareDialogObjs = this.state.shareDialogObjs;
                updatedShareDialogObjs[2] = await messageBuilder(
                    this.props.http,
                    MSG_SOCIAL_ORGANIZATION_DEFAULT,
                    {
                        organization: res.data.charity.name
                    },
                    getClosestRegion(customer.location, regions),
                    `${process.env.REACT_APP_ORIGIN_URL}/${res.data.charity.url}`,
                    this.props.lang
                );

                this.setState({
                    changingCharities: false,
                    reloadingCharity: false,
                    charityBulks: res.data.charityBulks,
                    charityBulksLoaded: true,
                    charityShares: res.data.charity.shares || 0,
                    shareDialogObjs: updatedShareDialogObjs,
                    shareDialogindex: updatedShareDialogIndex,
                    minRedemptionCharity: res.data.minRedemptionCharity,
                    accountRedemptionOptions: {
                        ...this.state.accountRedemptionOptions,
                        charity: res.data.redemptionOptions
                    },
                    charityPages: {
                        [`/customers/${this._id}/${charity_id}/charity`]: loc('dashboard', this.props.lang),
                        [`/customers/${this._id}/${charity_id}/charityHistory`]: loc('history', this.props.lang),
                        [`/customers/${this._id}/${charity_id}/charityProfile`]: terms.ORGANIZATION_NAME,
                        [`/customers/${this._id}/${charity_id}/charityDonations`]: loc('donations', this.props.lang),
                        [`/customers/${this._id}/${charity_id}/charityReceipts`]: loc('taxReceipts', this.props.lang),
                        [`/customers/${this._id}/${charity_id}/charitySubdivisions`]: loc(
                            'subdivisions',
                            this.props.lang
                        )
                    },
                    // #2992 - TODO: add logic block to display questionairre popup for differentiating groups/charities
                    charityTypeDialogOpen: !customer.adminView && isEXPRegion() && _.isNil(res.data.charity.charityType)
                });
            }
        } else {
            this.setState({
                charity: {},
                charityBulks: [],
                charityRedemptions: [],
                charityBulksLoaded: false
            });
        }
    };
    shareDialogindexOnNextClose;

    changeShareAndFeedbackText = async messageObj => {
        this.setState({ shareDialogObj: messageObj });
    };

    handleUpdateCurrentLocation = currentLocation => {
        this.setState({ currentLocation });
    };

    handleCharityClick = charity => () => {
        this.setState({ charitySelected: charity, charityInfoDialogOpen: true });
    };

    handleDropLocationClick = dropLocation => () => {
        const { customer } = this.state;
        if (profileNotComplete(customer)) {
            return;
        }

        this.setState({ openDropAndGo: true, dropAndGoCollector: dropLocation });
        this.handlePickupOpen()();
    };

    handleReturnSiteClick = (collector, dropLocations) => () => {
        const { customer } = this.state;
        if (profileNotComplete(customer)) {
            return;
        }

        this.setState({
            openDropAndGo: true,
            dropAndGoCollector: collector,
            dropAndGoTechnologies: dropLocations
        });
        this.handlePickupOpen()();
    };

    handleActionFabClicked = () => {
        this.setState(prevState => ({
            fabOpen: !prevState.fabOpen
        }));
    };

    handleAccessDoor = () => {
        this.setState({
            openDropAndGo: true,
            overrideTechnologyFilter: ['Access Door'],
            overrideTechnologyTypeForInstructions: 'accessDoor'
        });
        this.handlePickupOpen()();
    };

    handleChildAccountClick = (account, location) => () => {
        this.setState({
            pastLocationSelected: location,
            childAccountSelected: account,
            childAccountInfoDialogOpen: true
        });
    };

    handlePastLocationClick = location => () => {
        const { customer } = this.state;
        if (profileNotComplete(customer)) {
            return;
        }

        if (_user.isCollectionCustomer(customer) && _user.getCollectionStatus(customer) != 'approved') {
            this.setState({ collectionsDialogOpen: true });
            return;
        }

        this.setState({ pickupDialogOpen: true, markerLocation: location });
        //Possible TODO: default to new pickup for this location
    };

    handleViewAccount = account => {
        const _id = _.get(account, '_id', '');
        if (!deviceHelper.isNativeApp()) {
            window.open(`/customers/${_id}`, '_blank');
        } else {
            // this.props.history.push(`/customers/${_id}/`);
            window.location.assign(`/customers/${_id}`);
        }
    };

    handleResetDropAndGo = () => {
        this.setState({
            openDropAndGo: false,
            dropAndGoCollector: undefined,
            dropAndGoTechnologies: []
        });
    };

    handleDonateClick = charity => async () => {
        const { http } = this.props;
        const { customer } = this.state;

        let res = await http.post(`/users/${customer._id}/updateCharitySelected`, { charity_id: charity._id });
        if (res.ok) {
            this.props.onSnackbar(loc('customerSnackBar3', this.props.lang, { charity: charity.name }));
            this.reloadCustomer();
        }
    };

    handleDrawer = state => () => {
        this.setState({ drawerOpen: state });
    };

    handleMenu = e => {
        this.setState({ anchorEl: e.currentTarget });
    };

    handleAccountsMenu = state => e => {
        if (
            this.state.accounts.length <= 1 ||
            (this.state.rolePermissions &&
                (!_.get(this.state.rolePermissions, 'accounts.customer') ||
                    !_.get(this.state.rolePermissions, 'accounts.charity')))
        ) {
            return;
        }

        this.setState({ accountsAnchorEl: state ? e.currentTarget : undefined });
    };

    handleAccountsChange = index => () => {
        const { accounts, highlightAccountSwitch } = this.state;
        const oldIndex = this.state.accountSelectedIndex;

        this.setState({ accountSelectedIndex: index, accountsAnchorEl: undefined }, () => {
            if (accounts[index] === 'Customer') {
                this.props.history.push(`/customers/${this._id}`);
                this.setState({ shareDialogindex: 0 });
                if (highlightAccountSwitch) {
                    // only triggered on first charity login when switching to customer
                    this.setState({ highlightAccountSwitch: false });
                    this.setState({ howItWorksOpen: true });
                }
            } else if (accounts[index].split('-')[0] === 'Administrator') {
                if (index !== oldIndex) {
                    this.reloadCharity(
                        accounts[index].split('-')[1],
                        accounts[index].split('-')[1] !== _.get(this.state, 'charity._id', '')
                    );
                }
                this.props.history.push(`/customers/${this._id}/${accounts[index].split('-')[1]}/charity`);
                this.setState({ shareDialogindex: 2 });
            }
        });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleGenericError = handleClose => {
        handleClose();
        this.props.onSnackbar(loc('customerSnackBar4', this.props.lang), 'error');
    };

    handleNightModeToggle = () => {
        this.props.toggleNightMode();
    };

    handleCancelPickup = async (pickup, reason = 'N/A', setMode, nextMode = 'CREATE') => {
        const { http, lang } = this.props;
        let res = await http.post(`/pickups/${pickup._id}/cancel`, { reason });
        if (res.ok) {
            this.props.onSnackbar(loc('cancelPickup11', lang));
            setMode(nextMode);
            this.setState(
                {
                    pickupDialogOpen: false
                },
                async () => {
                    await this.reloadCustomer();
                }
            );
            clearInterval(this.etaTimer);
        }
    };

    handlePostponePickup = pickup => async () => {
        const { http } = this.props;

        let res = await http.post(`/pickups/${pickup._id}/postpone`);
        if (res.ok) {
            this.props.onSnackbar(loc('customerSnackBar5', this.props.lang));
            this.setState(
                {
                    pickupDialogOpen: false,
                    pickupConfirmationDialogOpen: false
                },
                async () => {
                    await this.reloadCustomer();
                }
            );
        }
    };

    handlePickupOpen = state => () => {
        this.handlePickupDialog(true)();
        this.handleDrawer(false)();
    };

    handlePickupDialog = state => () => {
        if (!state) {
            this.reloadCustomer(); // Expensive, needs review
        }

        const { customer } = this.state;
        if (state && _user.isCollectionCustomer(customer) && _user.getCollectionStatus(customer) != 'approved') {
            this.setState({ collectionsDialogOpen: true });
            return;
        }

        this.setState({ pickupDialogOpen: state });
    };

    handleOpenPickupDialogWithMapMarker = pickup => {
        const { customer } = this.state;
        if (profileNotComplete(customer)) {
            return;
        }

        if (_user.isCollectionCustomer(customer) && _user.getCollectionStatus(customer) != 'approved') {
            this.setState({ collectionsDialogOpen: true });
            return;
        }

        this.setState({ pickupDialogOpen: true, markerLocation: pickup.location });
    };

    handleClosePickupDialog = () => {
        this.reloadCustomer(); // Expensive, needs review

        this.setState({
            pickupDialogOpen: false,
            pickupDialogPickups: this.state.pickups,
            markerLocation: null,
            overrideTechnologyFilter: null,
            overrideTechnologyTypeForInstructions: null
        });
    };

    handlePickupSubmitSuccess = () => {
        const lastPickup = _.last(this.state.pickups);
        if (pickupHelper.isPending(lastPickup)) {
            this.props.onSnackbar(loc('customerSnackBar6', this.props.lang));
        } else {
            this.props.onSnackbar(loc('customerSnackBar7', this.props.lang));
        }

        this.setState(
            {
                pickupDialogOpen: false
            },
            async () => {
                await this.reloadCustomer();
            }
        );
    };

    handleQRDialog = state => () => {
        const { customer } = this.state;
        if (state && _user.isCollectionCustomer(customer) && _user.getCollectionStatus(customer) != 'approved') {
            this.setState({ collectionsDialogOpen: true });
            return;
        }

        this.setState({ qrDialogOpen: state, openDropAndGo: false, pickupDialogOpen: false });
        this.handleDrawer(false)();
    };

    handleCloseVerificationDialog = () => () => {
        this.setState({ collectionsDialogOpen: false });
    };

    handleResendVerificationEmail = async () => {
        const { customer } = this.state;

        let res = await this.props.http.post(`/users/${customer._id}/resendVerificationEmail`, {});
        if (res.ok) {
            this.props.onSnackbar(loc('verificationEmailSent', this.context.lang));
        }
    };

    handleVerifyEmailDialog = state => () => {
        this.setState({ verifyEmailDialogOpen: state });
        this.handleDrawer(false)();
    };

    handleRedemptionDialog = state => () => {
        this.setState({ redemptionDialogOpen: state, disablingAccount: false, deletingAccount: false });
        this.handleDrawer(false)();
    };

    handleAdminVerifyEmail = async () => {
        const { customer } = this.state;

        let res = await this.props.http.post(`/users/${customer._id}/adminVerifyEmail`, { email: customer.email });
        if (res.ok) {
            this.props.onSnackbar(loc('emailVerified', this.context.lang));
            this.setState({ verifyEmailDialogOpen: false });
            await this.reloadCustomer();
        }
    };

    handleCharityRedemptionDialog = state => () => {
        this.setState({ charityRedemptionDialogOpen: state });
        this.handleDrawer(false)();
    };

    handleRedeemSuccess = async () => {
        this.props.onSnackbar(loc('redemption51', this.context.lang));
        this.setState({
            redemptionDialogOpen: false,
            charityRedemptionDialogOpen: false
        });

        if (this.state.disablingAccount) {
            await this.reloadCustomer();
            await this.reloadCharity();
            await this.handleDisableAccount();
        } else if (this.state.deletingAccount) {
            await this.reloadCustomer();
            await this.reloadCharity();
            await this.handleDeleteAccount();
        }
    };

    handleDeleteAccount = async reason => {
        const {
            bulks,
            redemptions,
            redemptionPending,
            tips,
            charityBulks,
            customer,
            operator,
            deleteReason,
            commoditiesAvailable
        } = this.state;
        if (!_.isNil(reason)) {
            this.setState({
                deleteReason: reason
            });
        }
        const customerFinancialStats = getCustomerFinancialStats(
            bulks,
            commoditiesAvailable,
            redemptions,
            redemptionPending,
            tips
        );
        const charityFinancialStats = getCharityFinancialStats(charityBulks, commoditiesAvailable);
        if (customerFinancialStats.balance > 0) {
            this.setState({
                redemptionDialogOpen: true,
                charityRedemptionDialogOpen: false,
                deletingAccount: true
            });
        } else if (charityFinancialStats.balance > 0) {
            this.setState({
                charityRedemptionDialogOpen: true,
                redemptionDialogOpen: false,
                deletingAccount: true
            });
        } else {
            const res = await this.props.http.post(`/users/${customer._id}/deleteUserAccount`, {
                deleteReason: _.isNil(reason) ? deleteReason : reason
            });
            if (res.ok) {
                if (_user.isSystemAdmin(this.props.auth) || _user.isInternalRole(this.props.auth)) {
                    await this.props.http.post(`/users/${customer._id}/adminLogoutUser`);
                    this.props.history.push('/');
                    this.props.onSnackbar(loc('accountDeleteSucceeded', this.context.lang), 'error');
                } else {
                    this.props.logOut();
                    this.props.onSnackbar(loc('accountDeleteSucceeded', this.context.lang), 'error');
                }
            } else {
                this.props.onSnackbar(loc('accountDeleteFailed', this.context.lang), 'error');
            }
        }
    };

    handleDisableAccount = async reason => {
        const {
            bulks,
            redemptions,
            redemptionPending,
            tips,
            charityBulks,
            customer,
            operator,
            disableReason,
            commoditiesAvailable
        } = this.state;
        if (!_.isNil(reason)) {
            this.setState({
                disableReason: reason
            });
        }
        const customerFinancialStats = getCustomerFinancialStats(
            bulks,
            commoditiesAvailable,
            redemptions,
            redemptionPending,
            tips
        );
        const charityFinancialStats = getCharityFinancialStats(charityBulks, commoditiesAvailable);
        if (customerFinancialStats.balance > 0) {
            this.setState({
                redemptionDialogOpen: true,
                charityRedemptionDialogOpen: false,
                disablingAccount: true
            });
        } else if (charityFinancialStats.balance > 0) {
            this.setState({
                charityRedemptionDialogOpen: true,
                redemptionDialogOpen: false,
                disablingAccount: true
            });
        } else {
            const updatedDisableReason = `${_.get(disableReason, 'reason')}. Comments: ${_.get(
                disableReason,
                'comments'
            )} `;
            const res = await this.props.http.post(`/users/${customer._id}/setUserDisabled`, {
                disabled: true,
                disableReason: updatedDisableReason
            });
            if (res.ok) {
                if (_user.isSystemAdmin(this.props.auth) || _user.isInternalRole(this.props.auth)) {
                    await this.props.http.post(`/users/${customer._id}/adminLogoutUser`);
                    this.props.history.push('/');
                } else {
                    this.props.logOut();
                }
            } else {
                this.props.onSnackbar(loc('accountDeleteFailed', this.context.lang), 'error');
            }
        }
    };

    handleDonateSuccess = async charityName => {
        this.props.onSnackbar(
            loc('redemption63', this.context.lang, {
                charity: charityName
            })
        );
        this.setState(
            {
                redemptionDialogOpen: false
            }
            // async () => {
            //     await this.reloadCustomer();
            // }
        );

        if (this.state.disablingAccount) {
            await this.reloadCustomer();
            this.handleDisableAccount();
        } else if (this.state.deletingAccount) {
            await this.reloadCustomer();
            this.handleDeleteAccount();
        }
    };

    handleCharityInfoEdit = () => {
        const { customer, charity } = this.state;

        this.props.history.push(
            `/customers/${customer._id}/${charity._id}/charityProfile?${queryString.stringify({ back: true })}`
        );
    };

    handleHelpClick = event => {
        this.setState({ helpDialogEl: event.currentTarget });
    };

    handleHelpClose = () => {
        this.setState({ helpDialogEl: null });
    };

    handleLangClick = event => {
        this.setState({ langDialogEl: event.currentTarget });
    };

    handleLangClose = () => {
        this.setState({ langDialogEl: null });
    };

    handleSwitchAccountDialog = state => () => {
        this.setState({ switchAccountDialogOpen: state });
    };

    handleCharityTypeDialog = state => () => {
        this.setState({ charityTypeDialogOpen: state });
    };

    handleFAQ = state => () => {
        this.setState({ faqOpen: state, helpDialogEl: null });
    };

    handleHowItWorks = state => () => {
        if (!this.props.helpCenterFunctions.showHowItWorks) {
            return;
        }

        const { highlightAccountSwitch } = this.state;

        this.setState({ howItWorksOpen: state, helpDialogEl: null });

        if (!state && highlightAccountSwitch) {
            this.setState({ switchAccountDialogOpen: true });
        }
    };

    handleCharityGrowthPlanDialog = open => () => {
        this.setState({ charityGrowthPlanOpen: open });
    };

    handleContactDialog = state => () => {
        this.setState({
            contactDialogOpen: state,
            helpDialogEl: null
        });
    };

    handleContactSubmitSuccess = () => {
        this.props.onSnackbar(
            process.env.REACT_APP_REGION_EXT === 'EXP'
                ? loc('customerSnackBar13', this.props.lang)
                : loc('customerSnackBar8', this.props.lang)
        );
        this.setState({
            contactDialogOpen: false
        });
    };

    handlePickupConfirmationDialog = state => () => {
        this.setState({ pickupConfirmationDialogOpen: state });
    };

    handlePickupConfirmationSubmitSuccess = () => {
        this.props.onSnackbar(loc('customerSnackBar9', this.props.lang));
        this.setState(
            {
                nextUnconfirmedPickup: undefined
            },
            () => {
                this.reloadCustomer();
            }
        );
    };

    handleCharitySelectedDialog = state => () => {
        this.setState({ charitySelectedDialogOpen: state });
    };

    handleCharitySelectedSubmitSuccess = charity => {
        this.props.onSnackbar(loc('customerSnackBar10', this.props.lang));
        this.setState(
            {
                charityInfoDialogOpen: false,
                charitySelected: charity
            },
            () => this.reloadCustomer()
        );
    };

    handleCharityInfoDialog = (state, charitySelected = undefined) => () => {
        if (_.isNil(charitySelected)) {
            this.setState({ charityInfoDialogOpen: state });
        } else {
            this.setState({ charityInfoDialogOpen: state, charitySelected });
        }
    };

    handleChildAccountInfoDialog = state => () => {
        this.setState({ childAccountInfoDialogOpen: state });
    };

    handleDonate = charity => () => {
        this.setState({ charitySelected: charity, charitySelectedDialogOpen: true });
    };

    handleRateOurAppDialog = state => () => {
        this.setState({ rateOurAppDialogOpen: state });
    };

    handleRateChoice = choice => async () => {
        let res = await this.props.http.post('/users/rateChoice', { choice });
        if (res.ok) {
            this.setState({ rateOurAppDialogOpen: false });
        }
    };

    handleShareDialogCloseHelper = () => {
        this.setState({ shareDialogOpen: false });
        //reduce flickering
        setTimeout(() => {
            this.setState({ shareDialogShowSingleScreen: false });
        }, 100);
    };

    handleShareDialogClose = () => {
        const { shareDialogindexOnNextClose } = this.state;
        this.handleShareDialogCloseHelper();
        if (!_.isNil(shareDialogindexOnNextClose)) {
            this.setState({ shareDialogindex: shareDialogindexOnNextClose, shareDialogindexOnNextClose: null });
        }
        this.reloadCustomer();
    };

    handleShareDialog = state => async () => {
        const { shareDialogindexOnNextClose } = this.state;

        this.setState({
            shareDialogOpen: state,
            askForFeedback: false,
            shareDialogShowSingleScreen: state,
            shareDialogStep: SHARE_STEP
        });

        if (state === false && !_.isNil(shareDialogindexOnNextClose)) {
            this.setState({ shareDialogindex: shareDialogindexOnNextClose, shareDialogindexOnNextClose: null });
        }
    };

    //opens share dialog with share text set to organization share text
    handleShareDialogWithOrganizationText = state => (name, url) => async () => {
        const { customer, regions, charity } = this.state;
        const { shareDialogindex } = this.state;
        let updatedShareDialogObjs = this.state.shareDialogObjs;
        const charityName = state === true && !_.isNil(name) ? name : _.get(charity, 'name', '');
        const charityURL = state === true && !_.isNil(url) ? url : _.get(charity, 'url', '');

        updatedShareDialogObjs[2] = await messageBuilder(
            this.props.http,
            MSG_SOCIAL_ORGANIZATION_DEFAULT,
            {
                organization: charityName
            },
            getClosestRegion(_.get(customer, 'location', { lat: 0, lng: 0 }), regions),
            `${process.env.REACT_APP_ORIGIN_URL}/${charityURL}`,
            this.props.lang
        );
        this.setState({
            shareDialogObjs: updatedShareDialogObjs
        });
        this.setState({
            shareDialogindex: 2,
            shareDialogOpen: state,
            //askForFeedback: false,
            //shareDialogOnlyShowRateStep: false,
            shareDialogindexOnNextClose: shareDialogindex,
            shareDialogShowSingleScreen: true,
            shareDialogObjs: updatedShareDialogObjs
        });
    };

    handleShareDialogStep = shareDialogStep => {
        this.setState({ drawerOpen: false, shareDialogStep });
    };

    handleCustomerReferralDialog = () => {
        this.setState({
            drawerOpen: false,
            shareDialogOpen: true,
            shareDialogStep: REFERRAL_STEP,
            shareDialogShowSingleScreen: true
        });
    };

    handleCustomerShareDialog = () => {
        this.setState({
            drawerOpen: false,
            shareDialogOpen: true,
            shareDialogStep: SHARE_STEP,
            shareDialogShowSingleScreen: true
        });
    };

    handleCustomerFeedbackDialog = (isLastInteraction = false) => {
        this.setState({
            drawerOpen: false,
            shareDialogOpen: true,
            shareDialogStep: SUMMARY_STEP,
            shareDialogShowSingleScreen: false,
            isLastInteraction
        });
    };

    handleLastInteraction = () => {
        this.handleCustomerFeedbackDialog(true);
    };

    handleGoBack = () => {
        this.props.history.goBack();
    };

    handleRecurringFeesDialog = state => () => {
        this.setState({ recurringFeesDialogOpen: state });
    };

    handleExitView = () => {
        const { auth } = this.props;
        if (auth.accountType === 'Customer') {
            window.location.assign(`/`);
            return;
        }

        // Operators only
        let historyLink = `/operators/${auth._id}`;
        if (auth.accountType === 'Collector Administrator') historyLink += '/collector';

        this.props.history.push(historyLink);
        this.props.onAdminTheme(false);
    };

    handleUserPreferencesDialog = state => () => {
        this.setState({ userPreferencesDialogOpen: state });
    };

    handleVolumeReportDialog = state => () => {
        this.setState({ volumeReportDialogOpen: state });
    };

    handleSystemHistoryDialog = state => () => {
        this.setState({ systemHistoryDialogOpen: state });
    };

    handleIPConflictDialog = state => () => {
        this.setState({ IPConflictDialogOpen: state });
    };

    // when on capacitor OR iOS/Android browser
    handleShareNative = async (subject, message, url) => {
        if (!_.isNil(window.navigator.share)) {
            window.navigator
                .share({
                    title: subject,
                    text: message,
                    ...(!_.isNil(url) ? { url } : {})
                })
                .then(() => {
                    this.handleSuccessfulShare();
                })
                .catch(error => console.error('Error sharing', error));
        } else {
            try {
                await Share.share({
                    title: subject,
                    text: message,
                    url,
                    dialogTitle: subject
                });
            } catch (err) {}
            this.handleSuccessfulShare();
        }
    };

    handleSuccessfulShare = async () => {
        const { customer, charity, charityShares, rolePermissions } = this.state;
        const { http } = this.props;

        const isCharityAdmin = !_.isNil(customer.charities) && !_.isEmpty(customer.charities);
        const hasCharityPermissions = _.get(rolePermissions, 'accounts.charity');
        if (isCharityAdmin && (!rolePermissions || hasCharityPermissions)) {
            const res = await http.post(`/charities/${charity._id}/updateShares`, { shares: 1 });
            if (res.ok) {
                this.setState({ charityShares: charityShares + 1 });
                this.props.onSnackbar(loc('customerSnackBar11', this.props.lang));
            }
        }
    };

    handleCharityStatsDialog = state => () => {
        this.setState({ charityStatsDialogOpen: state });
    };

    handleReferralDialog = state => () => {
        this.setState({ referralDialogOpen: state });
    };

    handleHelpDialog = state => () => {
        this.setState({ helpDialogOpen: state });
    };

    handleRateAppDrawer = () => {
        this.setState({ shareDialogOpen: true, shareDialogStep: RATE_STEP, shareDialogShowSingleScreen: true });
        this.handleDrawer(false)();
    };

    handleRateApp = async () => {
        const { http } = this.props;
        const { customer, nextPlatformToRateOn, collectorRatingPlatforms } = this.state;

        if (customer.adminView) {
            if (deviceHelper.isNativeApp()) {
                try {
                    await Browser.open({ url: nextPlatformToRateOn.url });
                } catch (err) {
                    console.log(err);
                }
            } else {
                window.open(nextPlatformToRateOn.url, '_blank');
            }
            return;
        }

        const customerCopy = _.cloneDeep(customer);

        if (!_.isNil(nextPlatformToRateOn)) {
            http.post(`/users/customerRate`, { ratingPlatform: nextPlatformToRateOn.ratingPlatform._id });
            if (deviceHelper.isNativeApp()) {
                try {
                    await Browser.open({ url: nextPlatformToRateOn.url });
                } catch (err) {
                    console.log(err);
                }
            } else {
                window.open(nextPlatformToRateOn.url, '_blank');
            }
            //customerCopy.platformsRatedOn.push(nextPlatformToRateOn.ratingPlatform._id);
            let platformsRatedOn = _.get(customerCopy, 'platformsRatedOn', []);
            platformsRatedOn.push(nextPlatformToRateOn.ratingPlatform._id);
            customerCopy.platformsRatedOn = platformsRatedOn;
            this.setState({
                customer: customerCopy,
                nextPlatformToRateOn: getNextPlatformToRateOn(collectorRatingPlatforms, customerCopy)
            });
        }
    };

    /*handleToggleCharityTaskComplete = taskId => {
        const { charity } = this.state;

        let charityCopy = _.cloneDeep(charity);
        let charityTask = _.find(charityCopy.charityTasks, charityTask => charityTask.task._id === taskId);
        charityTask.complete = !charityTask.complete;

        this.setState({ charity: charityCopy });
    };*/

    handleCharityTasksUpdate = updatedCharityTasks => {
        const { charity } = this.state;

        let charityCopy = _.cloneDeep(charity);
        /*const charityTaskIndex = _.findIndex(
            charityCopy.charityTasks,
            charityTask => charityTask.task === updatedCharityTask.task
        );
        charityCopy.charityTasks[charityTaskIndex] = updatedCharityTask;*/

        charityCopy.charityTasks = updatedCharityTasks;

        this.setState({ charity: charityCopy });
    };

    handleCharityTaskOrderCreated = (taskId, details) => {
        const { charity } = this.state;

        let charityCopy = _.cloneDeep(charity);

        for (const task of charityCopy.charityTasks) {
            if (task.task._id === taskId) {
                task.orderCreated = true;
                task.informationProvided = details;
                break;
            }
        }

        this.setState({ charity: charityCopy });
    };

    handleCharityHighResImageUploaded = imageURL => {
        const { charity } = this.state;

        let charityCopy = _.cloneDeep(charity);
        charityCopy.highResImage = imageURL;

        this.setState({ charity: charityCopy });
    };

    setCharityTaskI = newIndex => {
        this.setState({ charityTaskI: newIndex });
    };

    handleCharityTaskSelected = index => {
        this.setState({ charityGrowthPlanOpen: true, charityTaskI: index });
    };

    handleRedirectToPage = page => {
        const { customer, charity, charityPages } = this.state;

        if (
            _.some(_.keys(charityPages), charityPage => {
                return charityPage.includes(page);
            })
        ) {
            this.props.history.push(`/customers/${customer._id}/${charity._id}/${page}`);
        } else {
            this.props.history.push(`/customers/${customer._id}/${page}`);
        }
    };

    setDeactivateMenuEl = newVal => {
        this.setState({ deactivateMenuEl: newVal });
    };
    updateSize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    selectLang = async lang => {
        if (lang == this.props.lang) return;

        const { http, setLang } = this.props;

        setLang(lang);
        await http.post(`/users/${this._id}/updateLang`, { lang }, true);
    };

    render() {
        const {
            theme,
            auth,
            location,
            history,
            google,
            http,
            org,
            subdivisionWithoutSpaces,
            refreshRequired,
            charityEnabled,
            scanQREnabled,
            showQREnabled,
            pickupsEnabled,
            collectionProgramEnabled,
            promosEnabled
        } = this.props;

        const {
            loading,
            customer,
            pickups,
            pickupDialogPickups,
            markerLocation,
            rates,
            allRates,
            redemptions,
            accountRedemptionOptions,
            generalRedemptionOptions,
            bulks,
            invoices,
            tips,
            customerHistory,
            taxReceipts,
            charity,
            charityBulks,
            charityBulksLoaded,
            charityRedemptions,
            charities,
            collectors,
            charitySelected,
            childAccountSelected,
            pastLocationSelected,
            liveInfo,
            defaultCoordinates,
            enableCustomerPickupImageUpload,
            accounts,
            accountSelectedIndex,
            minRedemptionCustomer,
            minRedemptionCharity,
            redemptionFee,
            disableInAppRedemptionCancel,
            drawerOpen,
            accountsAnchorEl,
            pickupDialogOpen,
            recurringFeesDialogOpen,
            qrDialogOpen,
            redemptionDialogOpen,
            disablingAccount,
            disableReason,
            deletingAccount,
            deleteReason,
            charityRedemptionDialogOpen,
            helpDialogEl,
            langDialogEl,
            contactDialogOpen,
            highlightAccountSwitch,
            switchAccountDialogOpen,
            charityTypeDialogOpen,
            howItWorksOpen,
            faqOpen,
            charityGrowthPlanOpen,
            pickupConfirmationDialogOpen,
            nextUnconfirmedPickup,
            charitySelectedDialogOpen,
            charityInfoDialogOpen,
            childAccountInfoDialogOpen,
            shareDialogStep,
            shareDialogOpen,
            shareDialogObjs,
            shareDialogindex,
            //shareDialogOnlyShowRateStep,
            askForFeedback,
            charityStatsDialogOpen,
            referralDialogOpen,
            isCordova,
            // usersRegistered,
            usersDonating,
            userPreferencesDialogOpen,
            volumeReportDialogOpen,
            charityShares,
            enableReferrals,
            enableTipping,
            isSMSEnabled,
            charityTaskI,
            commoditiesAvailable,
            commodityProjections,
            regions,
            collectorRatingPlatforms,
            nextPlatformToRateOn,
            growthPlanEnabled,
            minNumberTasksToBeFeatured,
            dropLocations,
            openDropAndGo,
            charityPromo,
            dropAndGoCollector,
            dropAndGoTechnologies,
            totalCustomerCount,
            shareAndFeedbackDialogConfig,
            refundSummaryConfig,
            shareDialogShowSingleScreen,
            reactivateAccountDialogOpen,
            accountDeletedDialogOpen,
            systemHistoryDialogOpen,
            IPConflictDialogOpen,
            charitiesRendered,
            mapCenter,
            twoFactorAuthenticationWarning,
            services,
            customerCanChangeAccountType,
            rolePermissions,
            deactivateMenuEl,
            windowWidth,
            customerPages,
            charityPages,
            // displayQRCode,
            // allowRequestLabels,
            multiBagDrops,
            isLastInteraction,
            childAccountBulks,
            childAccountTips,
            childAccountRedemptions,
            dropLocationMarkerOverride,
            charityMarkerOverride,
            taxGroups,
            currentLocation,
            emailVerificationRequired,
            emailVerificationRequiredForETransfer,
            adminPermissions,
            // customerPermissions,
            overrideTechnologyFilter,
            overrideTechnologyTypeForInstructions,
            verifyEmailDialogOpen,
            collectionsDialogOpen
        } = this.state;

        const { lang } = this.context;

        const shareScreenEnabled = _.get(shareAndFeedbackDialogConfig, 'shareScreen.enabled', false);

        let accountTypesAuthorized = ['Customer', 'System Administrator', 'Collector Administrator', ...ROLES];
        let id_match = true;

        // NOTE: Administrators skip IDs comparison
        if (auth.accountType === 'Customer') {
            id_match =
                auth._id.toLowerCase() === this._id.toLowerCase() ||
                auth.multipleAccountAccessList.map(a => a.toLowerCase()).includes(this._id.toLowerCase());
        }

        if (!_.includes(accountTypesAuthorized, auth.accountType) || !id_match) {
            return (
                <Error
                    header="Error 403"
                    description={`${loc('customerSnackBar12', this.props.lang)} ${
                        auth.accountType
                    } ${auth._id.toLowerCase()} ${this._id.toLowerCase()}`}
                />
            );
        }

        if (loading) {
            return <LoadingScreen />;
        }

        if (
            (profileNotComplete(customer) || !emailIsVerified(customer)) &&
            location.pathname !== `/customers/${customer._id}/profile`
        ) {
            return <Redirect to={`/customers/${customer._id}/profile`} />;
        }

        const accountSelected = accounts[accountSelectedIndex];
        const backButtonShown = queryString.parse(location.search).back;

        const completePickups = _(pickups)
            .filter(p => p.complete && !p.aborted)
            .sortBy(p => p.completionDate)
            .value();

        const incompletePickups = _(pickups)
            .filter(p => !p.complete && !p.aborted)
            .sortBy(p => p.date)
            .value();

        const pickupPending = !_.isEmpty(incompletePickups);
        const lastPickup = pickupPending ? _.last(incompletePickups) : _.last(completePickups);

        const lastRedemption = _.last(redemptions);
        const redemptionPending = (!_.isNil(lastRedemption) && !lastRedemption.complete) || false;

        const customerFinancialStats = getCustomerFinancialStats(
            bulks,
            commoditiesAvailable,
            redemptions,
            redemptionPending,
            tips
        );
        const customerEnvironmentalStats = getEnvironmentalStats(customerFinancialStats);

        //let lastBulk = _.last(bulks);

        let completedUnsummarizedBulks = _.filter(bulks, function(bulk) {
            return bulkHelper.isCompleted(bulk) && !bulkHelper.isSummarized(bulk);
        });

        // let lastCompletedBulk = _.last(
        //     _.filter(bulks, function(bulk) {
        //         return bulkHelper.isCompleted(bulk);
        //     })
        // );

        // let completedAndCountedPickups = _.filter(
        //     pickups,
        //     p => pickupHelper.numberOfCountedBulksOnPickup(p) === _.get(p, 'bulks', []).length
        // );

        // let lastCompletedAndCountedPickup = _.last(_.sortBy(completedAndCountedPickups, p => p.completionDate));

        let completedPickups = _.filter(pickups, p => pickupHelper.isComplete(p));
        let lastCompletedPickup = _.last(_.sortBy(completedPickups, p => p.completionDate));

        let groupedSummarizedBulks = _(bulks)
            .filter(
                bulk =>
                    // bulkHelper.isCompleted(bulk) &&
                    _.get(bulk, 'summarized.value', false) && !_.isNil(bulk, 'summarized.dateUpdated')
            )
            .groupBy('summarized.dateUpdated')
            .value();

        let lastSummarizedBulksDate = _.orderBy(
            Object.keys(groupedSummarizedBulks),
            date => moment(date).format('X'),
            'desc'
        )[0];

        let lastSummarizedBulks = groupedSummarizedBulks[lastSummarizedBulksDate];

        let bulksToShow = isLastInteraction ? lastSummarizedBulks : completedUnsummarizedBulks;

        let charityPreferred = getCharityPreferred(
            pickups,
            redemptions,
            customer.charityPreferred,
            _.get(customer, 'charitySelected._id', null),
            charities
        );
        let subdivisionPreferred = getSubdivisionPreferred(
            pickups,
            redemptions,
            _user.getSubdivisionPreferred(customer)
        );

        let customerInCollectionProgram = _.get(customer, 'collectionProgramPreference', false);

        let homePickups, homeOrganization;
        if (accountSelected === 'Customer') {
            homePickups = pickupPending ? incompletePickups : undefined;
        } else if (accountSelected.split('-')[0] === 'Administrator') {
            let accountSelectedCharity = _.find(customer.charities, c => c._id === accountSelected.split('-')[1]);
            homeOrganization = {
                name: accountSelectedCharity.name,
                location: _.get(accountSelectedCharity, 'location', {})
            };
        }

        const maplessPages = [
            `/customers/${customer._id}/${charity._id}/charityDonations`,
            `/customers/${customer._id}/${charity._id}/charityReceipts`,
            `/customers/${customer._id}/${charity._id}/charitySubdivisions`
        ];
        const mapShown = !maplessPages.includes(location.pathname);

        // Charity:
        const lastCharityRedemption = _.last(charityRedemptions);
        const charityRedemptionPending = !_.isNil(lastCharityRedemption) && !lastCharityRedemption.complete;
        const charityAmountPending = charityRedemptionPending
            ? lastCharityRedemption.amount - lastCharityRedemption.fee
            : undefined;

        const charityFinancialStats = getCharityFinancialStats(charityBulks, commoditiesAvailable);

        const charityEnvironmentalStats = {
            emissions: charityFinancialStats.totalQuantity * 0.0971718,
            energy: charityFinancialStats.totalQuantity * 0.3330947,
            landfill: charityFinancialStats.totalQuantity * 0.0511431
        };

        let screenIsVerySmall = windowWidth <= 400;

        const showHelpButton = Object.values(this.props.helpCenterFunctions).includes(true);
        const showLanguageButton = availableLangs.length > 1;

        const filteredServices = _.filter(
            services,
            s =>
                _.get(s, 'collectionProgram', false) === _.get(customer, 'collectionProgramPreference', false) &&
                !s.disabled &&
                !s.hidden
        );
        const qrServiceEnabled = !_.isNil(
            _.find(
                filteredServices,
                s =>
                    s.pickupType === QR_SCAN ||
                    ([EXPRESS, DROPANDGO].includes(s.pickupType) &&
                        _.get(s, 'technologies', []).includes('Reverse Vending Machine')) ||
                    (s.pickupType === INSTRUCTIONS && s.instructionType === 'reverseVendingMachine')
            )
        );
        const accessDoorServiceEnabled = !_.isNil(
            _.find(
                filteredServices,
                s =>
                    s.pickupType === ACCESS_DOOR ||
                    ([EXPRESS, DROPANDGO].includes(s.pickupType) &&
                        _.get(s, 'technologies', []).includes('Access Door')) ||
                    (s.pickupType === INSTRUCTIONS && s.instructionType === 'accessDoor')
            )
        );

        let widgetFab = [];
        if ((scanQREnabled || showQREnabled) && qrServiceEnabled) {
            widgetFab.push({
                icon: <Icon style={{ color: theme.palette.primary[500] }}>qr_code_scanner</Icon>,
                onClick: this.handleQRDialog(true),
                dataCy: 'customer-site-qr-scan-button',
                text: loc('scanQRCode', lang)
            });
        }
        if (accessDoorServiceEnabled) {
            widgetFab.push({
                icon: <MDIcon path={mdiLockCheck} size={1.2} color={theme.palette.primary[500]} />,
                onClick: () => this.handleAccessDoor(),
                dataCy: 'customersite-door-fab',
                text: loc('openAccessDoorStep', lang)
            });
        }
        if (enableReferrals) {
            widgetFab.push({
                icon: <DynamicGift theme={theme} scale={widgetFab.length > 0 ? 0.3 : 0.5} />,
                onClick: this.handleCustomerReferralDialog,
                dataCy: 'customer-site-referral-dialog-button',
                text: loc('earnMoney', lang)
            });
        }

        return (
            <div id="customer-site" style={{ height: '100%' }}>
                {Number(window.innerWidth) < 1280 && (
                    <>
                        <SwipeableDrawer
                            open={drawerOpen}
                            onOpen={this.handleDrawer(true)}
                            onClose={this.handleDrawer(false)}
                        >
                            <DrawerContent
                                drawerWidth={DRAWER_WIDTH}
                                auth={auth}
                                http={http}
                                lang={lang}
                                homePath={
                                    accountSelected.includes('Administrator')
                                        ? `${this.homePath}/${accountSelected.split('-')[1]}`
                                        : this.homePath
                                }
                                location={location}
                                adminView={customer.adminView}
                                customer={customer}
                                customerPages={customerPages}
                                charityPages={charityPages}
                                accounts={accounts}
                                accountSelected={accountSelected}
                                accountSelectedIndex={accountSelectedIndex}
                                accountsAnchorEl={accountsAnchorEl}
                                pickupPending={pickupPending}
                                helpDialogEl={helpDialogEl}
                                enableReferrals={enableReferrals}
                                highlightAccountSwitch={highlightAccountSwitch}
                                lastInteractionHidden={_.isNil(lastSummarizedBulks) || _.isEmpty(lastSummarizedBulks)}
                                onHelpDialog={this.handleHelpClick}
                                onGrowthPlanDialog={this.handleCharityGrowthPlanDialog}
                                onDrawer={this.handleDrawer}
                                onAccountsMenu={this.handleAccountsMenu}
                                onAccountsChange={this.handleAccountsChange}
                                onPickupOpen={this.handlePickupOpen}
                                onRedemptionDialog={this.handleRedemptionDialog}
                                onCharityRedemptionDialog={this.handleCharityRedemptionDialog}
                                onReferralDialog={this.handleReferralDialog}
                                onShare={this.handleCustomerShareDialog}
                                onCustomerReferralDialog={this.handleCustomerReferralDialog}
                                onNightModeToggle={this.handleNightModeToggle}
                                onExitView={this.handleExitView}
                                onLastInteraction={this.handleLastInteraction}
                                onPreferencesDialog={this.handleUserPreferencesDialog}
                                onVolumeReport={this.handleVolumeReportDialog}
                                onOpenSystemHistory={this.handleSystemHistoryDialog}
                                onRateApp={this.handleRateAppDrawer}
                                onRecurringFeesOpen={this.handleRecurringFeesDialog}
                                onQRDialog={this.handleQRDialog}
                                logOut={this.props.logOut}
                                logOutAll={this.props.logOutAll}
                                charity={charity}
                                nextPlatformToRateOn={nextPlatformToRateOn}
                                shareAndFeedbackDialogConfig={shareAndFeedbackDialogConfig}
                                rolePermissions={rolePermissions}
                                // displayQRCode={displayQRCode}
                                // allowRequestLabels={allowRequestLabels}
                                onOpenIPConflict={this.handleIPConflictDialog}
                                scanQREnabled={scanQREnabled}
                                showQREnabled={showQREnabled}
                                qrServiceEnabled={qrServiceEnabled}
                                accessDoorServiceEnabled={accessDoorServiceEnabled}
                                showHelpButton={showHelpButton}
                                changingCharities={this.state.changingCharities}
                                adminPermissions={adminPermissions}
                                // customerPermissions={customerPermissions}
                                handleAccessDoor={this.handleAccessDoor}
                                collectionProgramEnabled={collectionProgramEnabled}
                                growthPlanEnabled={growthPlanEnabled}
                            />
                        </SwipeableDrawer>
                        <AppBar
                            id="main-appbar"
                            position="fixed"
                            style={{ backgroundColor: theme.palette.envColor[500] }}
                            className="appbar"
                        >
                            <Toolbar>
                                {backButtonShown ? (
                                    <IconButton
                                        data-cy="customersite-back-button"
                                        color="inherit"
                                        onClick={this.handleGoBack}
                                    >
                                        <Icon>arrow_back</Icon>
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        className={highlightAccountSwitch ? 'pulse' : null}
                                        data-cy="customersite-drawer-button"
                                        color="inherit"
                                        onClick={this.handleDrawer(true)}
                                    >
                                        <Icon>menu</Icon>
                                    </IconButton>
                                )}
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{ flex: 1, marginLeft: 16, fontWeight: 400 }}
                                >
                                    {customerPages[location.pathname] || charityPages[location.pathname]}
                                </Typography>
                                {!isProductionEnv && (
                                    <Typography
                                        variant={screenIsVerySmall ? 'body1' : 'h4'}
                                        style={{ flex: 1 }}
                                        color="inherit"
                                    >
                                        <b>{getEnvName()}</b>
                                    </Typography>
                                )}
                                <Typography color="inherit" variant="caption">
                                    ID: {customer.uniqueID}
                                </Typography>
                                <React.Fragment>
                                    {screenIsVerySmall &&
                                    (showHelpButton || showLanguageButton || shareScreenEnabled) ? (
                                        <>
                                            <IconButton
                                                onClick={event => {
                                                    this.setDeactivateMenuEl(event.currentTarget);
                                                }}
                                                data-cy="help-menu-drop-down"
                                            >
                                                <MDIcon path={mdiArrowDownDropCircle} size={1} color={'white'} />
                                            </IconButton>
                                            <Menu
                                                data-cy="help-menu"
                                                anchorEl={deactivateMenuEl}
                                                open={Boolean(deactivateMenuEl)}
                                                onClose={() => {
                                                    this.setDeactivateMenuEl(null);
                                                }}
                                            >
                                                {showHelpButton && (
                                                    <MenuItem
                                                        style={{ justifyContent: 'center' }}
                                                        onClick={this.handleHelpClick}
                                                        data-cy="help-center-menu-button"
                                                    >
                                                        <IconButton
                                                            color="inherit"
                                                            aria-owns={helpDialogEl ? 'help-menu' : null}
                                                            aria-haspopup="true"
                                                        >
                                                            <Icon>help</Icon>
                                                        </IconButton>
                                                    </MenuItem>
                                                )}

                                                {showLanguageButton && (
                                                    <MenuItem
                                                        style={{ justifyContent: 'center' }}
                                                        onClick={this.handleLangClick}
                                                    >
                                                        <IconButton
                                                            color="inherit"
                                                            aria-owns={langDialogEl ? 'lang-menu' : null}
                                                            aria-haspopup="true"
                                                        >
                                                            <Icon>language</Icon>
                                                        </IconButton>
                                                    </MenuItem>
                                                )}
                                                {shareScreenEnabled &&
                                                    (isCordova || !_.isNil(window.navigator.share) ? (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={() =>
                                                                this.handleShareNative(
                                                                    shareDialogObjs[shareDialogindex].subject,
                                                                    shareDialogObjs[shareDialogindex].message,
                                                                    shareDialogObjs[shareDialogindex].url
                                                                )
                                                            }
                                                        >
                                                            <IconButton color="inherit" aria-label="Share">
                                                                <Icon>share</Icon>
                                                            </IconButton>
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem
                                                            style={{ justifyContent: 'center' }}
                                                            onClick={this.handleCustomerShareDialog}
                                                        >
                                                            <IconButton color="inherit" aria-label="Share">
                                                                <Icon>share</Icon>
                                                            </IconButton>
                                                        </MenuItem>
                                                    ))}
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            {showHelpButton && (
                                                <IconButton
                                                    color="inherit"
                                                    aria-owns={helpDialogEl ? 'help-menu' : null}
                                                    aria-haspopup="true"
                                                    onClick={this.handleHelpClick}
                                                >
                                                    <Icon>help</Icon>
                                                </IconButton>
                                            )}
                                            {showLanguageButton && (
                                                <IconButton
                                                    color="inherit"
                                                    aria-owns={langDialogEl ? 'lang-menu' : null}
                                                    aria-haspopup="true"
                                                    onClick={this.handleLangClick}
                                                >
                                                    <Icon>language</Icon>
                                                </IconButton>
                                            )}
                                            {shareScreenEnabled && (
                                                <span>
                                                    {isCordova || !_.isNil(window.navigator.share) ? (
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="Share"
                                                            onClick={() =>
                                                                this.handleShareNative(
                                                                    shareDialogObjs[shareDialogindex].subject,
                                                                    shareDialogObjs[shareDialogindex].message,
                                                                    shareDialogObjs[shareDialogindex].url
                                                                )
                                                            }
                                                        >
                                                            <Icon>share</Icon>
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            color="inherit"
                                                            aria-label="Share"
                                                            onClick={this.handleCustomerShareDialog}
                                                        >
                                                            <Icon>share</Icon>
                                                        </IconButton>
                                                    )}
                                                </span>
                                            )}
                                        </>
                                    )}
                                </React.Fragment>
                            </Toolbar>
                        </AppBar>
                    </>
                )}
                {Number(window.innerWidth) >= 1280 && (
                    <>
                        <Drawer open={drawerOpen} variant="permanent" onClose={this.handleDrawer(false)}>
                            <DrawerContent
                                drawerWidth={DRAWER_WIDTH}
                                auth={auth}
                                http={http}
                                lang={lang}
                                homePath={
                                    accountSelected.includes('Administrator')
                                        ? `${this.homePath}/${accountSelected.split('-')[1]}`
                                        : this.homePath
                                }
                                location={location}
                                adminView={customer.adminView}
                                customer={customer}
                                customerPages={customerPages}
                                charityPages={charityPages}
                                accounts={accounts}
                                accountSelected={accountSelected}
                                accountSelectedIndex={accountSelectedIndex}
                                accountsAnchorEl={accountsAnchorEl}
                                pickupPending={pickupPending}
                                helpDialogEl={helpDialogEl}
                                enableReferrals={enableReferrals}
                                highlightAccountSwitch={highlightAccountSwitch}
                                lastInteractionHidden={_.isNil(lastSummarizedBulks) || _.isEmpty(lastSummarizedBulks)}
                                onHelpDialog={this.handleHelpClick}
                                onGrowthPlanDialog={this.handleCharityGrowthPlanDialog}
                                onDrawer={this.handleDrawer}
                                onAccountsMenu={this.handleAccountsMenu}
                                onAccountsChange={this.handleAccountsChange}
                                onPickupOpen={this.handlePickupOpen}
                                onRedemptionDialog={this.handleRedemptionDialog}
                                onCharityRedemptionDialog={this.handleCharityRedemptionDialog}
                                onReferralDialog={this.handleReferralDialog}
                                onShare={this.handleCustomerShareDialog}
                                onCustomerReferralDialog={this.handleCustomerReferralDialog}
                                onNightModeToggle={this.handleNightModeToggle}
                                onExitView={this.handleExitView}
                                onLastInteraction={this.handleLastInteraction}
                                onPreferencesDialog={this.handleUserPreferencesDialog}
                                onVolumeReport={this.handleVolumeReportDialog}
                                onOpenSystemHistory={this.handleSystemHistoryDialog}
                                onOpenIPConflict={this.handleIPConflictDialog}
                                onRateApp={this.handleRateAppDrawer}
                                onRecurringFeesOpen={this.handleRecurringFeesDialog}
                                onQRDialog={this.handleQRDialog}
                                // displayQRCode={displayQRCode}
                                // allowRequestLabels={allowRequestLabels}
                                logOut={this.props.logOut}
                                logOutAll={this.props.logOutAll}
                                charity={charity}
                                nextPlatformToRateOn={nextPlatformToRateOn}
                                shareAndFeedbackDialogConfig={shareAndFeedbackDialogConfig}
                                rolePermissions={rolePermissions}
                                scanQREnabled={scanQREnabled}
                                showQREnabled={showQREnabled}
                                qrServiceEnabled={qrServiceEnabled}
                                accessDoorServiceEnabled={accessDoorServiceEnabled}
                                showHelpButton={showHelpButton}
                                changingCharities={this.state.changingCharities}
                                adminPermissions={adminPermissions}
                                // customerPermissions={customerPermissions}
                                handleAccessDoor={this.handleAccessDoor}
                                collectionProgramEnabled={collectionProgramEnabled}
                                growthPlanEnabled={growthPlanEnabled}
                            />
                        </Drawer>
                        <AppBar
                            id="main-appbar"
                            position="fixed"
                            elevation={0}
                            style={{
                                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                                marginLeft: DRAWER_WIDTH,
                                backgroundColor: theme.palette.envColor[500]
                            }}
                        >
                            <Toolbar>
                                <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{
                                        flex: 1,
                                        marginLeft: theme.spacing.unit * 2,
                                        fontFamily: 'Oswald',
                                        fontWeight: 300,
                                        letterSpacing: 1,
                                        display: 'flex'
                                    }}
                                >
                                    {auth.accountType === 'System Administrator' ||
                                    ROLES.includes(this.props.auth.accountType) ? (
                                        <span>ADMIN VIEW</span>
                                    ) : (
                                        <img
                                            src={_.get(this, 'props.brandLogoOverride.url', logo)}
                                            alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                                            style={{
                                                position: 'relative',
                                                top: 4,
                                                height: 42
                                            }}
                                        />
                                    )}
                                </Typography>
                                {!isProductionEnv && (
                                    <Typography variant="h4" style={{ flex: 1 }} color="inherit">
                                        <b>{getEnvName()}</b>
                                    </Typography>
                                )}
                                <React.Fragment>
                                    {showHelpButton && (
                                        <IconButton
                                            color="inherit"
                                            aria-owns={helpDialogEl ? 'help-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleHelpClick}
                                        >
                                            <Icon>help</Icon>
                                        </IconButton>
                                    )}
                                    {showLanguageButton && (
                                        <IconButton
                                            color="inherit"
                                            aria-owns={langDialogEl ? 'lang-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleLangClick}
                                        >
                                            <Icon>language</Icon>
                                        </IconButton>
                                    )}
                                    {shareScreenEnabled && (
                                        <span>
                                            {isCordova || !_.isNil(window.navigator.share) ? (
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="Share"
                                                    onClick={() =>
                                                        this.handleShareNative(
                                                            shareDialogObjs[shareDialogindex].subject,
                                                            shareDialogObjs[shareDialogindex].message,
                                                            shareDialogObjs[shareDialogindex].url
                                                        )
                                                    }
                                                >
                                                    <Icon>share</Icon>
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="Share"
                                                    onClick={this.handleShareDialog(true)}
                                                >
                                                    <Icon>share</Icon>
                                                </IconButton>
                                            )}
                                        </span>
                                    )}
                                </React.Fragment>
                            </Toolbar>
                        </AppBar>
                    </>
                )}
                <Grid
                    container
                    spacing={0}
                    id="main-grid"
                    style={{
                        height: `100%`,
                        paddingTop: `calc(var(--custom-safe-area-top) + ${getAppBarHeight(theme.breakpoints)}px)`,
                        flexDirection: mapShown ? 'row' : 'column'
                    }}
                >
                    {Number(window.innerWidth) >= 600 && (
                        <>
                            <Grid
                                item
                                sm={6}
                                md={8}
                                lg={8}
                                style={{
                                    position: 'relative',
                                    width: mapShown ? null : 0,
                                    height: mapShown ? null : 0
                                }}
                            >
                                <LargeMap
                                    google={google}
                                    showDirections={false}
                                    mapCenter={mapCenter}
                                    defaultCoordinates={defaultCoordinates}
                                    homePickups={homePickups}
                                    homeOrganization={homeOrganization}
                                    liveInfo={liveInfo}
                                    charities={accountSelected === 'Customer' ? charitiesRendered : []}
                                    childAccounts={customer.multipleAccountAccessList}
                                    pastLocations={customer.populatedPastLocations}
                                    dropLocations={dropLocations}
                                    charitySelected={charitySelected}
                                    commoditiesAvailable={commoditiesAvailable}
                                    onCharityClick={this.handleCharityClick}
                                    onDropLocationClick={this.handleDropLocationClick}
                                    onReturnSiteClick={this.handleReturnSiteClick}
                                    onChildAccountClick={this.handleChildAccountClick}
                                    onPastLocationClick={this.handlePastLocationClick}
                                    onDonateClick={this.handleDonateClick}
                                    onPickupDialog={this.handlePickupDialog}
                                    onOpenPickupDialogWithMapMarker={this.handleOpenPickupDialogWithMapMarker}
                                    dropLocationMarkerOverride={dropLocationMarkerOverride}
                                    charityMarkerOverride={charityMarkerOverride}
                                    currentLocation={currentLocation}
                                    pickupsEnabled={pickupsEnabled}
                                    charityEnabled={charityEnabled}
                                    collectors={collectors}
                                    /*mapEventListeners={[
                        {
                            name: 'center_changed',
                            handler: (e, map) => {
                                console.log(map.getCenter().lat(), map.getCenter().lng()); //TEST;
                            }
                        }
                    ]}*/
                                />

                                {accountSelected === 'Customer' && (
                                    <CombinedPickupsWidget
                                        assignRef={() => _.noop()}
                                        elevated
                                        history={history}
                                        customer={customer}
                                        pickups={pickups}
                                        bulks={bulks}
                                        charities={charities}
                                        onPickupDialog={this.handlePickupDialog}
                                        http={http}
                                        onReloadCustomer={this.reloadCustomer}
                                        onPickupConfirmationDialog={this.handlePickupConfirmationDialog(true)}
                                        charityPreferred={charityPreferred}
                                        charityEnabled={charityEnabled}
                                        onSnackbar={this.props.onSnackbar}
                                        regions={regions}
                                    />
                                )}

                                {accountSelected.includes('Administrator') &&
                                    !maplessPages.includes(location.pathname) && (
                                        <CharityInfoWidget
                                            assignRef={() => _.noop()}
                                            elevated
                                            charity={charity}
                                            onCharityStatsDialog={this.handleCharityStatsDialog}
                                            onSnackbar={this.props.onSnackbar}
                                            onCharityGrowthPlan={this.handleCharityGrowthPlanDialog}
                                            customersDonating={usersDonating}
                                            socialMediaShares={charityShares}
                                            onShareDialogOpen={this.handleShareDialogWithOrganizationText(true)(
                                                _.get(charity, 'name', ''),
                                                _.get(charity, 'url', '')
                                            )}
                                            onRedirectToPage={this.handleRedirectToPage}
                                            shareDialogEnabled={shareScreenEnabled}
                                            bulks={charityBulks}
                                            changingCharities={this.state.changingCharities}
                                            growthPlanEnabled={growthPlanEnabled}
                                        />
                                    )}
                                {/* {(!isEXPRegion() && displayQRCode) ||
                                (isEXPRegion() && allowRequestLabels && ( */}
                                {!maplessPages.includes(location.pathname) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: 'absolute',
                                            padding: theme.spacing.unit,
                                            bottom: 0,
                                            left: 0
                                        }}
                                    >
                                        <div>
                                            {widgetFab.length === 1 ? (
                                                <Fab
                                                    style={{
                                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                                        marginLeft: theme.spacing.unit,
                                                        marginBottom: theme.spacing.unit
                                                    }}
                                                    onClick={widgetFab[0].onClick}
                                                    data-cy={widgetFab[0].dataCy}
                                                >
                                                    {widgetFab[0].icon}
                                                </Fab>
                                            ) : widgetFab.length > 1 ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    {widgetFab.map((fab, index, array) => (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <Fab
                                                                style={{
                                                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                                                    marginLeft: theme.spacing.unit * 2,
                                                                    marginBottom: theme.spacing.unit,
                                                                    width: 40,
                                                                    height: 40,
                                                                    transform: this.state.fabOpen
                                                                        ? 'translateY(0px)'
                                                                        : `translateY(${(array.length - index) * 50 +
                                                                              6}px)`, // Moves the door icon up when fabOpen is true
                                                                    transition: 'transform 0.5s' // transition for the transform property
                                                                }}
                                                                data-cy={fab.dataCy}
                                                                onClick={fab.onClick}
                                                            >
                                                                {fab.icon}
                                                            </Fab>
                                                            <Typography
                                                                style={{
                                                                    marginLeft: theme.spacing.unit,
                                                                    marginBottom: theme.spacing.unit,
                                                                    opacity: this.state.fabOpen ? 1 : 0,
                                                                    transition: 'opacity 0.5s',
                                                                    color: theme.palette.primary[500],
                                                                    fontWeight: 'bold',
                                                                    fontSize: '12px'
                                                                }}
                                                            >
                                                                {fab.text}
                                                            </Typography>
                                                        </div>
                                                    ))}

                                                    <Fab
                                                        style={{
                                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                                            marginLeft: theme.spacing.unit,
                                                            marginBottom: theme.spacing.unit,
                                                            zIndex: 1 // Ensures this FAB is above the door icon
                                                        }}
                                                        data-cy="customersite-action-fab"
                                                        onClick={this.handleActionFabClicked}
                                                    >
                                                        <Icon
                                                            style={{
                                                                color: theme.palette.primary[500],
                                                                transform: this.state.fabOpen
                                                                    ? 'rotate(45deg)'
                                                                    : 'rotate(0deg)',
                                                                transition: 'transform 0.5s',
                                                                fontSize: '2.5rem'
                                                            }}
                                                        >
                                                            add
                                                        </Icon>
                                                    </Fab>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </>
                    )}

                    <Grid
                        item
                        xs={12}
                        sm={mapShown ? 6 : 12}
                        md={mapShown ? 4 : 12}
                        style={{ width: '100%', maxHeight: '100%', overflowY: 'auto' }}
                    >
                        {Number(window.innerWidth) < 600 && (
                            <>
                                <LargeMap
                                    offsetLegalInfo
                                    addRightOffsetLegalInfo={enableReferrals}
                                    google={google}
                                    showDirections={false}
                                    mapCenter={mapCenter}
                                    defaultCoordinates={defaultCoordinates}
                                    homePickups={homePickups}
                                    homeOrganization={homeOrganization}
                                    liveInfo={liveInfo}
                                    charities={accountSelected === 'Customer' ? charitiesRendered : []}
                                    dropLocations={dropLocations}
                                    childAccounts={customer.multipleAccountAccessList}
                                    pastLocations={customer.populatedPastLocations}
                                    charitySelected={charitySelected}
                                    commoditiesAvailable={commoditiesAvailable}
                                    onCharityClick={this.handleCharityClick}
                                    onDropLocationClick={this.handleDropLocationClick}
                                    onReturnSiteClick={this.handleReturnSiteClick}
                                    onChildAccountClick={this.handleChildAccountClick}
                                    onDonateClick={this.handleDonateClick}
                                    onPickupDialog={this.handlePickupDialog}
                                    onPastLocationClick={this.handlePastLocationClick}
                                    onOpenPickupDialogWithMapMarker={this.handleOpenPickupDialogWithMapMarker}
                                    /*always keep this on the dom to reduce map loads and just set its dimesions to 0 when we don't want to show it*/
                                    style={{
                                        height:
                                            location.pathname === `/customers/${customer._id}` ||
                                            location.pathname === `/customers/${customer._id}/${charity._id}/charity`
                                                ? '100%'
                                                : 0,
                                        width:
                                            location.pathname === `/customers/${customer._id}` ||
                                            location.pathname === `/customers/${customer._id}/${charity._id}/charity`
                                                ? '100%'
                                                : 0
                                    }}
                                    dropLocationMarkerOverride={dropLocationMarkerOverride}
                                    charityMarkerOverride={charityMarkerOverride}
                                    currentLocation={currentLocation}
                                    pickupsEnabled={pickupsEnabled}
                                    charityEnabled={charityEnabled}
                                    collectors={collectors}
                                />
                            </>
                        )}
                        <Switch>
                            <Route
                                exact
                                path="/customers/:_id"
                                render={props => (
                                    <Dashboard
                                        {...props}
                                        onResendVerificationEmail={this.handleResendVerificationEmail}
                                        onVerifyEmail={this.handleAdminVerifyEmail}
                                        adminView={customer.adminView}
                                        google={google}
                                        http={http}
                                        lang={lang}
                                        mapShown={mapShown}
                                        customer={customer}
                                        pickups={pickups}
                                        pickupPending={pickupPending}
                                        homePickups={homePickups}
                                        homeOrganization={homeOrganization}
                                        rates={rates}
                                        allRates={allRates}
                                        redemptions={redemptions}
                                        redemptionPending={redemptionPending}
                                        dropLocations={dropLocations}
                                        bulks={bulks}
                                        tips={tips}
                                        charities={charities}
                                        liveInfo={liveInfo}
                                        financialStats={customerFinancialStats}
                                        environmentalStats={customerEnvironmentalStats}
                                        regions={regions}
                                        commodities={commoditiesAvailable}
                                        commodityProjections={commodityProjections}
                                        reloadCustomer={this.reloadCustomer}
                                        onPickupDialog={this.handlePickupOpen}
                                        onRedemptionDialog={this.handleRedemptionDialog}
                                        onCharityInfoDialog={this.handleCharityInfoDialog}
                                        onDonate={this.handleDonate}
                                        onCharityClick={this.handleCharityClick}
                                        onDropLocationClick={this.handleDropLocationClick}
                                        onReturnSiteClick={this.handleReturnSiteClick}
                                        onDonateClick={this.handleDonateClick}
                                        onQRDialog={this.handleQRDialog}
                                        // displayQRCode={displayQRCode}
                                        // allowRequestLabels={allowRequestLabels}
                                        onSnackbar={this.props.onSnackbar}
                                        onCharityShareDialogOpen={this.handleShareDialogWithOrganizationText(true)}
                                        shareDialogEnabled={shareScreenEnabled}
                                        onPickupConfirmationDialog={this.handlePickupConfirmationDialog(true)}
                                        charityPreferred={charityPreferred}
                                        auth={this.props.auth}
                                        enableReferrals={enableReferrals}
                                        handleCustomerReferralDialog={this.handleCustomerReferralDialog}
                                        giftIcon={getGiftIcon()}
                                        printIcon={printIcon}
                                        childAccountBulks={childAccountBulks}
                                        childAccountTips={childAccountTips}
                                        childAccountRedemptions={childAccountRedemptions}
                                        charityEnabled={charityEnabled}
                                        scanQREnabled={scanQREnabled}
                                        showQREnabled={showQREnabled}
                                        qrServiceEnabled={qrServiceEnabled}
                                        accessDoorServiceEnabled={accessDoorServiceEnabled}
                                        emailVerificationRequired={emailVerificationRequired}
                                        emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                                        adminPermissions={adminPermissions}
                                        operator={auth}
                                        subject={shareDialogObjs[shareDialogindex].subject}
                                        message={shareDialogObjs[shareDialogindex].message}
                                        handleAccessDoor={this.handleAccessDoor}
                                        onNativeShare={this.handleShareNative}
                                        collectionProgramEnabled={collectionProgramEnabled}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/customers/:_id/history"
                                render={props => (
                                    <History
                                        {...props}
                                        rates={rates}
                                        allRates={allRates}
                                        customer={customer}
                                        pickups={pickups}
                                        redemptions={redemptions}
                                        bulks={bulks}
                                        tips={tips}
                                        invoices={invoices}
                                        customerHistory={customerHistory}
                                        commoditiesAvailable={commoditiesAvailable}
                                        taxReceipts={taxReceipts}
                                        taxGroups={taxGroups}
                                        disableInAppRedemptionCancel={disableInAppRedemptionCancel}
                                        adminView={customer.adminView}
                                        reloadCustomer={this.reloadCustomer}
                                        http={this.props.http}
                                        auth={this.props.auth}
                                        rolePermissions={rolePermissions}
                                        pickupsEnabled={pickupsEnabled}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/customers/:_id/profile"
                                render={props => (
                                    <Profile
                                        {...props}
                                        http={http}
                                        operator={auth}
                                        logOut={this.props.logOut}
                                        customer={customer}
                                        pickups={pickups}
                                        reloadCustomer={this.reloadCustomer}
                                        onHowItWorks={this.handleHowItWorks}
                                        onGrowthPlanDialog={this.handleCharityGrowthPlanDialog}
                                        onSnackbar={this.props.onSnackbar}
                                        handleToggleReactivateDialog={() =>
                                            this.setState({
                                                reactivateAccountDialogOpen: !this.state.reactivateAccountDialogOpen
                                            })
                                        }
                                        reactivateDialogOpen={this.state.reactivateAccountDialogOpen}
                                        twoFactorAuthenticationWarning={twoFactorAuthenticationWarning}
                                        balance={customerFinancialStats.balance}
                                        onLogoutAll={this.props.logOutAll}
                                        onDeleteAccount={this.handleDeleteAccount}
                                        onDisableAccount={this.handleDisableAccount}
                                        charityEnabled={charityEnabled}
                                        customerCanChangeAccountType={customerCanChangeAccountType}
                                        pickupsEnabled={this.props.pickupsEnabled}
                                        registrationConfig={this.props.registrationConfig}
                                        isSMSEnabled={isSMSEnabled}
                                        onVerifyEmail={this.handleAdminVerifyEmail}
                                        emailVerificationRequired={emailVerificationRequired}
                                        // customerPermissions={customerPermissions}
                                    />
                                )}
                            />
                            {!_.isEmpty(customer.charities) &&
                                !_.isNil(charity) &&
                                (!rolePermissions || _.get(rolePermissions, 'accounts.charity')) && (
                                    <React.Fragment>
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charity"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <CharityDashboard
                                                        {...props}
                                                        google={google}
                                                        http={http}
                                                        customer={customer}
                                                        charity={charity}
                                                        bulks={charityBulks}
                                                        bulksLoaded={charityBulksLoaded}
                                                        homeOrganization={homeOrganization}
                                                        redemptionPending={charityRedemptionPending}
                                                        redemptions={charityRedemptions}
                                                        // usersRegistered={usersRegistered}
                                                        financialStats={charityFinancialStats}
                                                        environmentalStats={charityEnvironmentalStats}
                                                        reloadCustomer={this.reloadCustomer}
                                                        reloadCharity={this.reloadCharity}
                                                        onCharityRedemptionDialog={this.handleCharityRedemptionDialog}
                                                        onCharityStatsDialog={this.handleCharityStatsDialog}
                                                        onCharityInfoEdit={this.handleCharityInfoEdit}
                                                        onSnackbar={this.props.onSnackbar}
                                                        shareDialogObj={shareDialogObjs[shareDialogindex]}
                                                        onReferralDialog={this.handleReferralDialog}
                                                        onCharityTaskSelected={this.handleCharityTaskSelected}
                                                        onCharityGrowthPlan={this.handleCharityGrowthPlanDialog}
                                                        customersDonating={usersDonating}
                                                        socialMediaShares={charityShares}
                                                        onShareDialogOpen={this.handleShareDialogWithOrganizationText(
                                                            true
                                                        )(_.get(charity, 'name', ''), _.get(charity, 'url', ''))}
                                                        shareDialogEnabled={shareScreenEnabled}
                                                        onRedirectToPage={this.handleRedirectToPage}
                                                        changingCharities={this.state.changingCharities}
                                                        adminPermissions={adminPermissions}
                                                        operator={auth}
                                                        growthPlanEnabled={growthPlanEnabled}
                                                        commodities={commoditiesAvailable}
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charityHistory"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <CharityHistory
                                                        {...props}
                                                        google={google}
                                                        http={http}
                                                        charity={charity}
                                                        bulks={charityBulks}
                                                        commoditiesAvailable={commoditiesAvailable}
                                                        redemptions={charityRedemptions}
                                                        reloadCharity={this.reloadCharity}
                                                        changingCharities={this.state.changingCharities}
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charityProfile"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <CharityProfile
                                                        {...props}
                                                        google={google}
                                                        http={http}
                                                        regions={regions}
                                                        charity={charity}
                                                        customer={customer}
                                                        reloadCharity={this.reloadCharity}
                                                        reloadCustomer={this.reloadCustomer}
                                                        adminView={customer.adminView}
                                                        reloadingCharity={this.reloadingCharity}
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charityDonations"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <CharityDonations
                                                        {...props}
                                                        bulks={charityBulks}
                                                        charity={charity}
                                                        redemptions={charityRedemptions}
                                                        taxReceiptsIssued={charity.taxReceiptsIssued}
                                                        teamsColumnVisible={!_.isEmpty(charity.subdivisions)}
                                                        reloadingCharity={this.reloadingCharity}
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charityReceipts"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <CharityTaxReceipts
                                                        {...props}
                                                        bulks={charityBulks}
                                                        charity={charity}
                                                        isSystemAdmin={
                                                            _user.isSystemAdmin(auth) || _user.isInternalRole(auth)
                                                        }
                                                        reloadCharity={this.reloadCharity}
                                                    />
                                                )
                                            }
                                        />
                                        <Route
                                            exact
                                            path="/customers/:_id/:charity_id/charitySubdivisions"
                                            render={props =>
                                                this.state.changingCharities ? (
                                                    <LoadingScreen />
                                                ) : (
                                                    <SubdivisionPermissions
                                                        {...props}
                                                        label={loc('dashboardSetting22', lang)}
                                                        url={_.get(charity, 'url')}
                                                        onSnackbar={this.props.onSnackbar}
                                                        reloadCharity={this.reloadCharity}
                                                        charity={charity}
                                                        reloadingCharity={this.reloadingCharity}
                                                    />
                                                )
                                            }
                                        />
                                    </React.Fragment>
                                )}
                            <Route render={props => <Error header="Error 404" description="Page was not found." />} />
                        </Switch>
                    </Grid>
                </Grid>

                {showHelpButton && (
                    <Menu
                        id="help-menu"
                        anchorEl={helpDialogEl}
                        open={Boolean(helpDialogEl)}
                        onClose={this.handleHelpClose}
                    >
                        {this.props.helpCenterFunctions.showHowItWorks && (
                            <MenuItem onClick={this.handleHowItWorks(true)} data-cy="how-it-works-menu-option">
                                <ListItemIcon>
                                    <Icon>settings</Icon>
                                </ListItemIcon>
                                <ListItemText primary={loc('howItWorks', lang)} />
                            </MenuItem>
                        )}
                        {this.props.helpCenterFunctions.showFaq && (
                            <MenuItem onClick={this.handleFAQ(true)} data-cy="frequent-questions-menu-option">
                                <ListItemIcon>
                                    <Icon>question_answer</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        process.env.REACT_APP_REGION_EXT === 'EXP'
                                            ? 'FAQ'
                                            : loc('frequentQuestions', lang)
                                    }
                                />
                            </MenuItem>
                        )}
                        {this.props.helpCenterFunctions.showContactUs && (
                            <MenuItem onClick={this.handleContactDialog(true)} data-cy="contact-us-menu-option">
                                <ListItemIcon>
                                    <Icon>chat</Icon>
                                </ListItemIcon>
                                <ListItemText primary={loc('contactUs', lang)} />
                            </MenuItem>
                        )}
                    </Menu>
                )}
                {showLanguageButton && (
                    <Menu
                        id="lang-menu"
                        anchorEl={langDialogEl}
                        open={Boolean(langDialogEl)}
                        onClose={this.handleLangClose}
                    >
                        {availableLangs.map(lang => {
                            return (
                                <MenuItem selected={this.props.lang == lang} onClick={() => this.selectLang(lang)}>
                                    {/* <ListItemIcon>
                                        <Icon>language</Icon>
                                    </ListItemIcon> */}
                                    <ListItemText primary={{ en: 'English (CAN)', fr: 'Français (CAN)' }[lang]} />
                                </MenuItem>
                            );
                        })}
                    </Menu>
                )}

                <PickupDialog
                    auth={auth}
                    google={google}
                    http={http}
                    open={pickupDialogOpen}
                    customer={customer}
                    markerLocation={markerLocation}
                    pickups={pickupDialogPickups}
                    pickupPending={pickupPending}
                    incompletePickups={incompletePickups}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    charitySelected={charitySelected}
                    subdivisionPreferred={subdivisionPreferred}
                    defaultCoordinates={defaultCoordinates}
                    history={history}
                    onCancel={this.handleCancelPickup}
                    onSubmitSuccess={this.handlePickupSubmitSuccess}
                    // onCancelSuccess={this.handlePickupCancelSuccess}
                    onFAQ={this.handleFAQ}
                    onClose={this.handleClosePickupDialog}
                    onGenericError={this.handleGenericError}
                    onSnackbar={this.props.onSnackbar}
                    isSystemAdmin={
                        _user.isSystemAdmin(auth) || _user.isCollectorAdmin(auth) || _user.isInternalRole(auth)
                    }
                    dropLocations={dropLocations}
                    collectors={collectors}
                    onReloadCustomer={this.reloadCustomer}
                    bulks={bulks}
                    openDropAndGo={openDropAndGo}
                    dropAndGoCollector={dropAndGoCollector}
                    dropAndGoTechnologies={dropAndGoTechnologies}
                    handleResetDropAndGo={this.handleResetDropAndGo}
                    regions={regions}
                    mapCenter={mapCenter}
                    enableTipping={enableTipping}
                    commoditiesAvailable={commoditiesAvailable}
                    multiBagDrops={multiBagDrops}
                    lockDonationPreference={
                        !_.isEmpty(customer.forcePromoCode) && !_.isNil(_.first(customer.forcePromoCode).charity)
                            ? true
                            : customer.lockDonationPreference
                    }
                    onQRDialog={this.handleQRDialog}
                    // allowRequestLabels={allowRequestLabels}
                    charityEnabled={charityEnabled}
                    handleUpdateCurrentLocation={this.handleUpdateCurrentLocation}
                    promosEnabled={this.props.promosEnabled}
                    enableCustomerPickupImageUpload={enableCustomerPickupImageUpload}
                    overrideTechnologyFilter={overrideTechnologyFilter}
                    overrideTechnologyTypeForInstructions={overrideTechnologyTypeForInstructions}
                    customerCanChangeAccountType={customerCanChangeAccountType}
                />

                <RecurringFeesDialog
                    http={http}
                    pickups={pickups}
                    customer={customer}
                    open={recurringFeesDialogOpen}
                    auth={auth}
                    reloadCustomer={this.reloadCustomer}
                    onClose={this.handleRecurringFeesDialog(false)}
                    onSnackbar={this.props.onSnackbar}
                />

                {qrDialogOpen && (
                    <QrScanDialog
                        http={http}
                        customer={customer}
                        // open={qrDialogOpen}
                        scanQREnabled={scanQREnabled}
                        showQREnabled={showQREnabled}
                        onClose={this.handleQRDialog(false)}
                    />
                )}

                {collectionsDialogOpen && (
                    <VerificationDialog
                        http={http}
                        customer={customer}
                        onClose={this.handleCloseVerificationDialog()}
                    />
                )}

                <RedemptionDialog
                    http={http}
                    operator={auth}
                    google={google}
                    redemptionType="customer"
                    customer={customer}
                    open={redemptionDialogOpen}
                    disablingAccount={disablingAccount}
                    deletingAccount={deletingAccount}
                    balance={customerFinancialStats.balance}
                    redemptions={redemptions}
                    redemptionOptions={{ ...accountRedemptionOptions, ...generalRedemptionOptions }}
                    redemptionPending={redemptionPending}
                    email={customer.email}
                    accountName={getCustomerName(customer)}
                    charities={charities}
                    charityPreferred={charityPreferred}
                    subdivisionPreferred={subdivisionPreferred}
                    minRedemptionCustomer={disablingAccount || deletingAccount ? 0 : minRedemptionCustomer}
                    minRedemptionCharity={disablingAccount || deletingAccount ? 0 : minRedemptionCharity}
                    redemptionFee={redemptionFee}
                    reloadCharity={this.reloadCharity}
                    reloadCustomer={this.reloadCustomer}
                    onClose={this.handleRedemptionDialog(false)}
                    onRedeemSuccess={this.handleRedeemSuccess}
                    onDonateSuccess={this.handleDonateSuccess}
                    lastPickup={lastPickup}
                    collectors={collectors}
                    lockDonationPreference={_.get(customer, 'lockDonationPreference', false)}
                    charityEnabled={charityEnabled}
                    handleVerifyEmailDialog={this.handleVerifyEmailDialog(true)}
                    emailVerificationRequired={emailVerificationRequired}
                    emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                    growthPlanEnabled={growthPlanEnabled}
                />
                {!_.isNil(charity) && (!rolePermissions || _.get(rolePermissions, 'accounts.charity')) && (
                    <RedemptionDialog
                        http={http}
                        operator={auth}
                        redemptionType="charity"
                        customer={customer}
                        open={charityRedemptionDialogOpen}
                        charity_id={charity._id}
                        balance={charityFinancialStats.balance}
                        redemptions={charityRedemptions}
                        redemptionOptions={{ ...accountRedemptionOptions, ...generalRedemptionOptions }}
                        redemptionPending={charityRedemptionPending}
                        amountPending={charityAmountPending}
                        email={_.get(charity, 'redemptionEmail', customer.email)}
                        minRedemptionCustomer={disablingAccount || deletingAccount ? 0 : minRedemptionCustomer}
                        minRedemptionCharity={disablingAccount || deletingAccount ? 0 : minRedemptionCharity}
                        redemptionFee={redemptionFee}
                        reloadCharity={this.reloadCharity}
                        reloadCustomer={this.reloadCustomer}
                        onRedeemSuccess={this.handleRedeemSuccess}
                        onClose={this.handleCharityRedemptionDialog(false)}
                        onSnackbar={this.props.onSnackbar}
                        charity={charity}
                        collectors={collectors}
                        disablingAccount={disablingAccount}
                        deletingAccount={deletingAccount}
                        growthPlanEnabled={growthPlanEnabled}
                    />
                )}

                {isEXPRegion() && !loading && (
                    <CharityTypeDialog
                        open={charityTypeDialogOpen}
                        reloadCharity={this.reloadCharity}
                        onClose={this.handleCharityTypeDialog(false)}
                        theme={theme}
                        http={http}
                        onSnackbar={this.props.onSnackbar}
                        charity={charity}
                    />
                )}
                <SwitchAccountDialog open={switchAccountDialogOpen} onClose={this.handleSwitchAccountDialog(false)} />
                {/* #2992 TODO - create dialog and handling */}
                {this.props.helpCenterFunctions.showHowItWorks && (
                    <HowItWorks
                        open={howItWorksOpen}
                        tabIndex={accountSelectedIndex}
                        onFAQ={this.handleFAQ}
                        onClose={this.handleHowItWorks(false)}
                        numUsers={totalCustomerCount}
                        isRegisteredUser={true}
                        collectionProgramEnabled={collectionProgramEnabled && customerInCollectionProgram}
                        collectionProgramFirst={collectionProgramEnabled && customerInCollectionProgram}
                    />
                )}
                <FAQ
                    open={faqOpen}
                    history={this.props.history}
                    tabIndex={accountSelectedIndex}
                    onClose={this.handleFAQ(false)}
                    http={this.props.http}
                />
                <ContactForm
                    http={http}
                    open={contactDialogOpen}
                    onSubmitSuccess={this.handleContactSubmitSuccess}
                    onClose={this.handleContactDialog(false)}
                />

                {!_.isEmpty(customer.charities) &&
                    (!rolePermissions || _.get(rolePermissions, 'accounts.charity')) &&
                    growthPlanEnabled && (
                        <CharityGrowthPlanDialog
                            charity={charity}
                            http={http}
                            onSnackbar={this.props.onSnackbar}
                            open={charityGrowthPlanOpen}
                            onClose={this.handleCharityGrowthPlanDialog(false)}
                            onUpdateCharityTasks={this.handleCharityTasksUpdate}
                            onCharityTaskOrderCreated={this.handleCharityTaskOrderCreated}
                            onCharityHighResImageUploaded={this.handleCharityHighResImageUploaded}
                            taskIndex={charityTaskI}
                            setTaskIndex={this.setCharityTaskI}
                            onEmailOutDirectly={this.handleReferralDialog}
                            customer={customer}
                            subject={shareDialogObjs[shareDialogindex].subject}
                            message={shareDialogObjs[shareDialogindex].message}
                            shareUrl={shareDialogObjs[shareDialogindex].url}
                            hashtags={shareDialogObjs[shareDialogindex].hashtags}
                            onNativeShare={this.handleShareNative}
                            onShare={this.handleSuccessfulShare}
                            minNumberTasks={minNumberTasksToBeFeatured}
                            //numUsers={totalCustomerCount}
                            onShareDialogOpen={this.handleShareDialog(true)}
                            shareDialogEnabled={shareScreenEnabled}
                            onRedirectToPage={this.handleRedirectToPage}
                            customersDonating={usersDonating}
                            shares={charityShares}
                            charityPromo={charityPromo}
                        />
                    )}

                {!_.isNil(nextUnconfirmedPickup) && (
                    <PickupConfirmationDialog
                        http={http}
                        open={pickupConfirmationDialogOpen}
                        pickup={nextUnconfirmedPickup}
                        commoditiesAvailable={commoditiesAvailable}
                        onCancel={this.handleCancelPickup}
                        onPostpone={this.handlePostponePickup}
                        onSubmitSuccess={this.handlePickupConfirmationSubmitSuccess}
                        onClose={this.handlePickupConfirmationDialog(false)}
                        customer={customer}
                        onSnackbar={this.props.onSnackbar}
                        reloadCustomer={this.reloadCustomer}
                    />
                )}

                <CharitySelectedDialog
                    http={http}
                    open={charitySelectedDialogOpen}
                    org={!_.isNil(charitySelected) ? charitySelected.url : org}
                    subdivisionWithoutSpaces={subdivisionWithoutSpaces}
                    customer_id={customer._id}
                    onSubmitSuccess={this.handleCharitySelectedSubmitSuccess}
                    onClose={this.handleCharitySelectedDialog(false)}
                    lockDonationPreference={customer.lockDonationPreference}
                />

                <CharityInfoDialog
                    open={charityInfoDialogOpen && !charitySelectedDialogOpen}
                    charity={charitySelected}
                    lockDonationPreference={customer.lockDonationPreference}
                    withDonate
                    onClose={this.handleCharityInfoDialog(false)}
                    onDonate={this.handleDonate}
                />

                <ChildAccountInfoDialog
                    open={childAccountInfoDialogOpen}
                    account={childAccountSelected}
                    location={pastLocationSelected}
                    onClose={this.handleChildAccountInfoDialog(false)}
                    onViewAccount={this.handleViewAccount}
                    childAccountBulks={childAccountBulks}
                    childAccountRedemptions={childAccountRedemptions}
                    childAccountTips={childAccountTips}
                    showImpactReport={shareScreenEnabled}
                    commodities={commoditiesAvailable}
                />
                {/* <RateOurAppDialog
                    open={rateOurAppDialogOpen}
                    onClose={this.handleRateOurAppDialog(false)}
                    onNo={this.handleRateChoice('NO')}
                    onLater={this.handleRateChoice('LATER')}
                    onYes={this.handleRateChoice('YES')}
                /> */}

                <ShareAndFeedbackDialog
                    http={http}
                    open={shareDialogOpen && !(auth.accountType === 'Customer' && auth._id !== customer._id)}
                    fullscreen={true}
                    askForFeedback={askForFeedback && !customer.adminView}
                    /*dialogTitle={shareDialogObjs[shareDialogindex].dialogTitle}
                    dialogMessage={shareDialogObjs[shareDialogindex].dialogDesc}*/
                    subject={shareDialogObjs[shareDialogindex].subject}
                    message={shareDialogObjs[shareDialogindex].message}
                    url={shareDialogObjs[shareDialogindex].url}
                    hashtags={shareDialogObjs[shareDialogindex].hashtags}
                    customer={customer}
                    isLastInteraction={isLastInteraction}
                    bulks={bulksToShow}
                    pickups={completedPickups}
                    commodities={commoditiesAvailable}
                    step={shareDialogStep}
                    tips={tips}
                    //onlyShowRateStep={shareDialogOnlyShowRateStep}
                    onStep={this.handleShareDialogStep}
                    onSubmitSuccess={this.onFeedbackSuccess}
                    onNativeShare={this.handleShareNative}
                    onShare={this.handleSuccessfulShare}
                    onSnackbar={this.props.onSnackbar}
                    onClose={this.handleShareDialogClose}
                    collectorRatingPlatforms={collectorRatingPlatforms}
                    nextPlatformToRateOn={nextPlatformToRateOn}
                    onRateApp={this.handleRateApp}
                    shareAndFeedbackDialogConfig={shareAndFeedbackDialogConfig}
                    refundSummaryConfig={refundSummaryConfig}
                    showSingleStep={shareDialogShowSingleScreen}
                    environmentalStats={customerEnvironmentalStats}
                    isAdmin={customer.adminView}
                    enableTipping={enableTipping}
                    rates={rates}
                    allRates={allRates}
                    customerFinancialStats={customerFinancialStats}
                    pickupsEnabled={this.props.pickupsEnabled}
                />

                <CharityStatsDialog
                    http={http}
                    open={charityStatsDialogOpen}
                    customersDonating={usersDonating}
                    socialMediaShares={charityShares}
                    subject={shareDialogObjs[shareDialogindex].subject}
                    message={shareDialogObjs[shareDialogindex].message}
                    url={shareDialogObjs[shareDialogindex].url}
                    hashtags={shareDialogObjs[shareDialogindex].hashtags}
                    onNativeShare={this.handleShareNative}
                    onShare={this.handleSuccessfulShare}
                    onClose={this.handleCharityStatsDialog(false)}
                    onReferralDialog={this.handleReferralDialog}
                />

                <ReferralDialog
                    http={http}
                    open={referralDialogOpen}
                    customer_id={customer._id}
                    onClose={this.handleReferralDialog(false)}
                    onSnackbar={this.props.onSnackbar}
                    charity_id={_.get(charity, '_id')}
                    charities={charities}
                />

                <UserPreferencesDialog
                    http={http}
                    collectors={collectors}
                    customer={customer}
                    operator={auth}
                    charity={charity}
                    redemptionOptions={accountRedemptionOptions}
                    accountSelected={accountSelected}
                    theme={theme}
                    open={userPreferencesDialogOpen}
                    onClose={this.handleUserPreferencesDialog(false)}
                    reloadCustomer={this.reloadCustomer}
                    reloadCharity={this.reloadCharity}
                    onSnackbar={this.props.onSnackbar}
                    charities={charities}
                    redemptionFee={redemptionFee}
                    redemptions={redemptions}
                    accountName={getCustomerName(customer)}
                    emailVerificationRequired={emailVerificationRequired}
                    emailVerificationRequiredForETransfer={emailVerificationRequiredForETransfer}
                    handleVerifyEmailDialog={this.handleVerifyEmailDialog(true)}
                    balance={customerFinancialStats.balance}
                    collectionProgramEnabled={collectionProgramEnabled}
                    promosEnabled={promosEnabled}
                    charityEnabled={charityEnabled}
                />

                <VolumeReportDialog
                    http={http}
                    customer={customer}
                    commodities={commoditiesAvailable}
                    theme={theme}
                    open={volumeReportDialogOpen}
                    onClose={this.handleVolumeReportDialog(false)}
                    reloadCustomer={this.reloadCustomer}
                    onSnackbar={this.props.onSnackbar}
                    taxGroups={taxGroups}
                />

                <ReactivateAccountDialog
                    open={reactivateAccountDialogOpen}
                    handleClose={() => this.setState({ reactivateAccountDialogOpen: false })}
                    onReloadCustomer={this.reloadCustomer}
                    onLogout={this.props.logOut}
                    customer={customer}
                    isAdmin={
                        this.props.auth.accountType === 'System Administrator' ||
                        ROLES.includes(this.props.auth.accountType)
                    }
                />

                <AccountDeletedDialog
                    open={accountDeletedDialogOpen}
                    handleClose={() => this.setState({ accountDeletedDialogOpen: false })}
                    onLogout={this.props.logOut}
                    isAdmin={
                        this.props.auth.accountType === 'System Administrator' ||
                        ROLES.includes(this.props.auth.accountType)
                    }
                />

                <VerifyEmailDialog
                    open={verifyEmailDialogOpen}
                    handleClose={() => this.setState({ verifyEmailDialogOpen: false })}
                    onVerifyEmail={this.handleAdminVerifyEmail}
                    onResendVerificationEmail={this.handleResendVerificationEmail}
                    isAdmin={
                        this.props.auth.accountType === 'System Administrator' ||
                        ROLES.includes(this.props.auth.accountType)
                    }
                    customer={customer}
                    theme={theme}
                />

                <SystemHistoryDialog
                    http={http}
                    theme={theme}
                    isAdmin={
                        this.props.auth.accountType === 'System Administrator' ||
                        ROLES.includes(this.props.auth.accountType)
                    }
                    open={systemHistoryDialogOpen}
                    onClose={this.handleSystemHistoryDialog(false)}
                    accountIds={
                        !_.isEmpty(_.get(customer, 'charities'))
                            ? [customer._id, ..._.get(customer, 'charities', []).map(c => c._id)]
                            : [customer._id]
                    }
                    lockToCustomer={customer._id}
                    auth={auth}
                />

                <IPConflictDialog
                    open={IPConflictDialogOpen}
                    onClose={this.handleIPConflictDialog(false)}
                    customer={customer}
                    theme={theme}
                    http={http}
                    handleViewAccount={this.handleViewAccount}
                />

                {!profileNotComplete(customer) && !customer.adminView && !shareDialogOpen && (
                    <Announcement
                        http={http}
                        bulks={bulks}
                        pickups={pickups}
                        customer={customer}
                        adminView={customer.adminView}
                        charityView={accountSelected.includes('Administrator')}
                        reload={accountSelected.includes('Administrator') ? this.reloadCharity : this.reloadCustomer}
                        hidden={charityTypeDialogOpen}
                    />
                )}

                <RefreshRequiredDialog open={refreshRequired && !deviceHelper.isNativeApp()} />
            </div>
        );
    }
}

export default withTheme()(CustomerSite);

function getGiftIcon() {
    if (isAUSRegion()) return ausGiftIcon;
    if (isMXDRegion()) return mxdGiftIcon;
    if (isCONRegion()) return conGiftIcon;

    return stdGiftIcon;
}
